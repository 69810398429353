import { useContext } from 'react'
import UserContext from '../context/UserContext'
import DataContext from '../context/DataContext'
import {Navigate} from 'react-router-dom'

export default () => {
    const {removeUser} = useContext(UserContext)
    const {removeData} = useContext(DataContext)

    const errorHandler = (error) => {
        const errCode = error.message

        switch (errCode) {
            case '401':
                removeUser()
                removeData()
                localStorage.removeItem('token')
                return (<Navigate to="/" />)
            case '400':
                return '400'
            default:
                return errCode
        }
    }

    return {errorHandler}
}