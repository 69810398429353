import React from 'react'

export default function Overview({clickedTo}) {
  return (
    <>
        <h2>Getting Started</h2>
        <br />
        <div>
          <h3>Welcome to Timing Solution Mobile!</h3>
          <p>This guide is built to help you get familiar with this service, to learn about what it is and how to use it.</p>
          <br />
          <h3>What is Timing Solution Mobile</h3>
          <p>Timing Solution Mobile (TS Mobile) is based on a financial analysis software Timing Solution. We took the most commonly used techniques and analysis tools of the software and put them into an online version.</p>
          <p>TS Mobile runs in your browser; it does not matter if you access it from your personal computer or your phone. It will work just as fast on either.</p>
          <br />
          <p>However there are a lot of things that TS Mobile is still not able to do compared to Timing Solution.</p>
          <p>Eventually you will reach a point where you may need a deeper analysis of the instrument you are working with, more than TS Mobile can provide. In this case, you may consider trying out Timing Solution software instead.</p>
          <p>Timing Solution offers a wide range of techniques and methods that are not found in any other financial analysis software. You can use these techniques to give you a competitive edge when trading!</p>
          <br />
          <p>In addition, TS Mobile comes at a discount when you purchase any version of Timing Solution Software.</p>
          <p>This way you can get the best of both worlds: the online access to your models with TS Mobile, and the ability to perform in-depth analysis of any instrument with Timing Solution!</p>
          <br />
          <h3>Using this guide</h3>
          <p>This guide is designed to explain features TS Mobile has to offer. There is a separate page in this guide devoted to each feature.</p>
          <p>Simply click on the feature's name from the menu on the left to get its detailed description and how to use it.</p>
          <br />
          <h3>First steps in TS Mobile</h3>
          <p>The very first thing you need to do is to find financial instruments to work with. Add them to your Favorites, so you will be able to monitor these when needed. (Financial Instruments are items that can be traded at financial markets; these are referred further as "instruments")</p>
          <br />
          <p>If you have a specific instrument in mind or a bunch to choose from, click the 'Menu' button in the top right corner of the page, then select 'Search Instrument'.</p>
          <p>From there you can either search for a particular symbol/name or you can browse instruments that are available. TS Mobile offers access to over 100,000 instruments worldwide, including stocks, bonds, commodities, futures, indexes, forex pairs, and crypto. You can learn more about it in the <span style={{cursor: 'pointer', color: 'blue', textDecoration: 'udnerline'}} onClick={() => clickedTo(2)}>Adding Instruments page here</span></p>
          <br />
          <p>If you are searching for new trading opportunities, you can do that as well. Select 'Search Instrument' from the 'Menu', and you will get a new screen.</p>
          <p>There you can set different search parameters, such as the dates to enter/exit the trade, and what Country and Sector you are interested in. Clicking 'Find' will show you the results according to your search parameters.</p>
          <p>You can learn more about the <span style={{cursor: 'pointer', color: 'blue', textDecoration: 'udnerline'}} onClick={() => clickedTo(3)}>Scanner feature here</span>.</p>
          <br />
          <p>Now that you have the instruments you are interested in, it is a good idea to familiarize yourself with the information related to each instrument. A detailed description of <span style={{cursor: 'pointer', color: 'blue', textDecoration: 'udnerline'}} onClick={() => clickedTo(7)}>Instrument page is available here</span>.</p>
          <p>From this point you can run different kinds of analysis on each instrument, such as <span style={{cursor: 'pointer', color: 'blue', textDecoration: 'udnerline'}} onClick={() => clickedTo(4)}>Intermarket Analysis</span>, <span style={{cursor: 'pointer', color: 'blue', textDecoration: 'udnerline'}} onClick={() => clickedTo(5)}>QSpectrum</span> and <span style={{cursor: 'pointer', color: 'blue', textDecoration: 'udnerline'}} onClick={() => clickedTo(6)}>Natural Cycle</span> modelling. Select either one of those topics from the left in this guide, to learn in detail about each method.</p>
          <br />
          <p>With all the tools available to you, TS Mobile helps you make a better trading strategy. Keep in mind that the final decision on what to trade and when is with you. TS Mobile simply is another tool in your trading toolbox.</p>
          <br />
          <p>We are constantly improving TS Mobile, and new features are being added continuously.</p>
          <br />
        </div>
    </>
  )
}
