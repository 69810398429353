import React, { useState, useEffect, useContext } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import Button from '../components/Button';
import Spinner from '../components/Spinner';
import DataContext from '../context/DataContext';
import moment from 'moment';

export default function IxDialog({closeClicked, instrument, ixData, instrData, stockIsFavorite, ixSelected}) {
    // context defs
    const { getIxData, showIxOnMain, addIxDataToModels, removeIxFromMain } = useContext(DataContext)

    // state defs
    const [shownIndex, setShownIndex] = useState(0)
    const [ixGraphData, setIxGraphData] = useState([])
    const [showingOnChart, setShowOnChart] = useState(false)

    const minTime = (moment(new Date()).valueOf()) - (1000*3600*24*180)

    const chartOptions = {
        tooltip: {
            formatter: function() {
                return 'Date: '+moment(this.x).format('L')
            }
        },
        rangeSelector: {
            // zoom buttons for 1m 3m YTD etc. disabled, change later if needed
            buttonTheme: {
                visibility: 'hidden'
            },
        },
        boost: {
            enabled: true,
            useGPUTranslations: true,
        },
        plotOptions: {
          series: {
            dataGrouping: {
                units: [[
                    'day', // unit name
                    [1] // allowed multiples
                ], [
                    'month',
                    [1, 2, 3, 4, 6]
                ]]
            },
            // general options for all series
            turboThreshold: 1000,
            // compare: 'percent',
            showInNavigator: true,
          }
        },
        // rangeSelector: {
        //     selected: 0
        // },
        xAxis: {
            min: minTime,
        //     max: maxTime
        },
        yAxis: [{

        }, {
            height: '100%',
            // floor: -1,
            // ceiling: 1,
            // visible: true
        }],
        series: [instrData[0], {
            name: "Comparison",
            color: "#FF0000",
            yAxis: 1,
            visible: true,
            data: ixGraphData
        }]
        // }, projLine]
    }

    // useEffect
    useEffect(() => {
        const ixContextRequest = async () => {
            setIxGraphData([])

            if (ixData.length) {
                const requestObj = {
                    le: ixData[shownIndex][0],
                    lag: parseInt(ixData[shownIndex][2].split('=')[1]),
                    status: ixData[shownIndex][4].split('=')[1]
                }
                const contextResponse = await getIxData(requestObj)
                
                if (contextResponse.length) {
                    setIxGraphData(contextResponse)
                } else {
                    alert('No data')
                }
    
                const leIndex = instrData.findIndex(item => item.name === 'I-'+ixData[shownIndex][0])
                if (leIndex > -1) {
                    setShowOnChart(true)
                } else {
                    setShowOnChart(false)
                }
            }
        }

        ixContextRequest()

        return () => {
            // cleanup
        }
    }, [shownIndex])


    // handlers
    const showLeadingCheckedHandler = async () => {
        if (!showingOnChart) {
            const addSuccess = await showIxOnMain(instrument, ixData[shownIndex][0])
            if (addSuccess) {
                const ixDataObject = {
                    name: "I-"+ixData[shownIndex][0],
                    data: ixGraphData,
                    header: "LE - "+ixData[shownIndex][0]+" | R - "+ixData[shownIndex][3].split('=')[1],
                    lineWidth: "1",
                    color: "#000",
                    opacity: "0.5",
                    yAxis: 1,
                    visible: true
                }
                await addIxDataToModels(ixDataObject)
                setShowOnChart(true)
                ixSelected()
            } else {
                setShowOnChart(true)
                ixSelected()
            }
        } else {
            await removeIxFromMain(instrument, ixData[shownIndex][0])
            setShowOnChart(false)
            ixSelected()
        }
    }

    // pre-render
    // navigation
    const ixList = ixData.map((item, index) => {
        const isInverted = item[4].split('=')[1][0]
        return (
            <li className="ix__item" onClick={() => setShownIndex(index)}>
                {item[0]}, Lag: {Math.abs(parseInt(item[2].split('=')[1]))}d{isInverted === 'I'? ', Inv' : null}
            </li>
        )
    })

    // show on main chart checkbox
    let showIxOnChart

    if (stockIsFavorite) {
        showIxOnChart = (<React.Fragment><input type="checkbox" checked={showingOnChart} onChange={showLeadingCheckedHandler} /><span> Show on main chart</span></React.Fragment>)
    } else {
        showIxOnChart = <div style={{textDecoration: "underline"}}>Add to Favorite to Display Intermarket on Main Chart</div>
    }

    // chart display
    let ixDisplay
    
    if (ixData.length) {
        ixDisplay =
        <div>
            <p>{ixData[shownIndex][0]} - {ixData[shownIndex][1]}</p>
            <p>{ixData[shownIndex][2]}, {ixData[shownIndex][4]}, {ixData[shownIndex][5]}</p>
            <br/>
            <p>Similarity: {ixData[shownIndex][3].split('=')[1]}</p>
            {showIxOnChart}
        </div>
    } else {
        ixDisplay = <div>No Intermarket Instruments</div>
    }

    let chartElement = 
    <Spinner />
    if (ixGraphData.length) {
        chartElement = <HighchartsReact options={chartOptions} highcharts={Highcharts} constructorType={'stockChart'} />
    } else if (!ixData.length) {
        chartElement = <Button className="button__model--cancel" onClick={closeClicked}>Close</Button>
    }

    return (
        <div className="ix">
            <div className="ix__header">
                <span>Intermarket Analysis for <b>{instrument}</b>. <b>{ixData.length}</b> Leading Indicators found</span>
                <Button className="button__model--cancel" onClick={closeClicked}>X</Button>
            </div>
            <div className="ix__content">
                <div className="ix__navigation">
                    <ol className="ix__list">
                        {ixList}
                    </ol>  
                </div>
                <div className="ix__data">
                    {ixDisplay}
                    {chartElement}
                </div>
            </div>
            {/* <div className="ix__bottom" style={{textAlign: "center"}}>
                <Button className="button__model--cancel" onClick={closeClicked}>Close</Button>
            </div> */}
        </div>
    )
}