import React, { useRef, useEffect} from 'react'

// export default function Dialog({open, type, outsideClick, children }) {
export default function Dialog({open, type, closeClicked, children }) {
    const wrapperRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                closeClicked()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [wrapperRef, closeClicked])

    if (!open) {
        return null
    }

    let dtype = "dialog__box"

    if (type) {
        dtype = "dialog__"+type
    }

    return (
        <div className="dialog__backdrop">
            <div ref={wrapperRef} className={`dialog ${dtype}`}>
                {children}
            </div>
        </div>
    )
}
