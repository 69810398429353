// Libraries Import
import React, { useContext, useState, useEffect } from 'react';
import { Route, Routes, Navigate, BrowserRouter, NavLink } from 'react-router-dom';

// Context Import
import UserContext from './context/UserContext'
import DataContext from './context/DataContext'
import useContexts from './hooks/useContextCommunicator'

// Layouts and Section Import
import Drawer from './components/Drawer'

import NavBar from './sections/NavBar'
import Login from './layouts/Login'
import Instrument from './layouts/Instrument'
// import Portfolios from './layouts/Portfolios'
import Scanner from './layouts/Scanner'
import Search from './layouts/Search'
import User from './layouts/User'
import Admin from './layouts/Admin'
import Help from './layouts/Help';
// import Compare from './layouts/Compare'

function App() {
  // Global state defs
  const {isAuth, stocks, customStocks, email} = useContext(UserContext)
  const {instrumentShown, setInstrumentShown, setAddedFromSearchFunc} = useContext(DataContext)

  // Local state defs
  const [menu, setMenu] = useState(false)
  const {tokenLogin, logoutCommand} = useContexts()
  const [url, setUrl] = useState('')


  // Current superuser definition
  const superUsers = ['tarassov.m@gmail.com', 'tanyata001@gmail.com', 'sergey1@alma.com']

  // vars
  useEffect(() => {
    try {
      tokenLogin()
    } catch (e) {
      console.log(e)
    }
    
  }, [tokenLogin])

  window.onpopstate = () => {
    setUrl(window.location.href)
  }

  if (url !== window.location.href) {
    setUrl(window.location.href)
  }


  // Handlers
  const logoutHandler = () => {
    logoutCommand()
    setMenu()
  }

  const showInstrument = (item) => {
    setInstrumentShown(item)
    setAddedFromSearchFunc(false)
  }

  // Pre-render
  let wrapperClass = 'wrapper'
  let favoriteStocks = null
  let favoriteStockList = null
  let customStockList = null

  let routes = null
  let instrumentRoute

  if (!isAuth) {
    if (localStorage.getItem('token')) {
      routes = null
    } else {
      routes = (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/help" element={<Help />} />
          {/* <Route path="/"><Navigate to="/login" /></Route> */}
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />}/>
        </Routes>
      )
    }
  } else {
    if (stocks.length || instrumentShown !== '') {
      instrumentRoute = <Route path="/instrument" element={<Instrument />} />
    } else {
      instrumentRoute = <Route path="/instrument" element={<Scanner />} />
    }
    routes = (
      
      <Routes>
        {instrumentRoute}
        <Route path="/scanner" element={<Scanner />} />
        <Route path="/search" element={<Search />} />
        {/* <Route path="/portfolios" element={Portfolios} /> */}
        <Route path="/user" element={<User />} />
        {/* <Route path="/compare" element={<Compare />} /> */}
        {/* <Route path="/"><Navigate to="/scanner" /></Route> */}
        <Route path="/login" element={<Navigate to="/scanner" />}/>
        <Route path="/help" element={<Help />} />
        {/* <Route path="" */}
        {
        superUsers.includes(email)?
        <Route path="/admin" element={<Admin />} />:
        <Route path="/admin" element={<Navigate to="/scanner" />}/>
        }
        <Route path="/" element={<Scanner />} />
      </Routes>
    )

    wrapperClass = 'wrapper scanner'

    favoriteStockList = stocks.map(item => {
      const uniqueKey = 'list'+item
      return (
        <NavLink 
          to='/instrument' 
          key={uniqueKey} 
          className="favoriteStocks--stock"
          onClick={() => showInstrument(item)}>
            <p>{item}</p>
        </NavLink>
      )
    })

    customStockList = customStocks.map(item => {
      const uniqueKey = 'list'+item
      return (
        <NavLink 
          to='/instrument' 
          key={uniqueKey} 
          className="favoriteStocks--stock"
          onClick={() => showInstrument(item)}>
            <p>{item}</p>
        </NavLink>
      )
    })

    favoriteStocks = (<div className='favoriteStocks'>
      <h4>My Favorites</h4>
      {favoriteStockList}
      {customStockList}
    </div>)

  }
  
  return (
    <BrowserRouter>
      <div className="App">
          <NavBar menuClicked={() => setMenu(true)} />
        <div className={url.endsWith('/help')? 'wrapper' : wrapperClass}>
          {url.endsWith('/help')?
          null
            :
          favoriteStocks
          }
          {routes}
        </div>
        <Drawer open={menu} backdropClicked={() => setMenu(false)}>
          <h3>MENU</h3>
          <p className="drawer__section-name">Instrument Selection</p>
          <NavLink to="/scanner" className="drawer__link" onClick={() => setMenu(false)}>Scan Predictable Zones</NavLink>
          <NavLink to="/search" className="drawer__link" onClick={() => setMenu(false)}>Search Instrument</NavLink>
          {/* <NavLink to="/compare" className="drawer__link" onClick={() => setMenu(false)}>Compare</NavLink> */}
          <p className="drawer__section-name">Management</p>
          {/* <NavLink to="/portfolios" className="drawer__link" onClick={() => setMenu(false)}>My Portfolios</NavLink> */}
          <NavLink to="/user" className="drawer__link" onClick={() => setMenu(false)}>User Page</NavLink>
          <NavLink to="/help" className="drawer__link" onClick={() => setMenu(false)}>Help</NavLink>
          {
          superUsers.includes(email)?
          <NavLink to="/admin" className="drawer__link" onClick={() => setMenu(false)}>Admin</NavLink>:
          null
          }
          <p className="drawer__link" onClick={logoutHandler}>Log out</p>
        </Drawer>
      </div>
    </BrowserRouter>
  );
}

export default App;