import React from 'react'
import DrawerComponent from './DrawerComponent'

export default function Dialog(props) {
    if (!props.open) {
        return null
    }

    const outsideClickHandler = () => {
        props.backdropClicked()
    }

    return (
        <div className="dialog__backdrop">
            <DrawerComponent outsideClick={outsideClickHandler}>
                {props.children}
            </DrawerComponent>
        </div>
    )
}
