import React, { useContext, useState } from 'react';
import {NavLink} from 'react-router-dom'

// context & hooks import
import UserContext from '../context/UserContext'
import useContexts from '../hooks/useContextCommunicator'

// component import
import Button from '../components/Button';
import LoadingBar from '../components/LoadingBar'

const Login = () => {
  // context def
  const { loading } = useContext(UserContext)
  const {loginCommand} = useContexts()

  // state def
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setError] = useState('')

  // handler def
  const formSubmit = async (e) => {
    e.preventDefault()
    try {
      setError('')
      await loginCommand(email, password)
    } catch (e) {
      if (e.message === 'Error: 400') {
        setError('Unable to Log In, please try again')
      } else {
        setError(e.message)
      }
    }
  }

  // pre-render
  let loadingDiv = null
  if (loading) {
    loadingDiv = <LoadingBar />
  }

  return (
    <div>
      <div className="login">
        {/* {error ? <p style={{color: 'red'}}>You have been logged out because someone has logged in with your credentials while this session was active. If it was you, please log in again. If it was not you, please change your password to keep your account secure and let us know. </p> : null} */}
        <form onSubmit={formSubmit}>
          <p>Login</p>
          <input 
            className="input" 
            type="email" 
            id="email" 
            placeholder="Your email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required
          />
          <br />
          <br/>
          <input 
            className="input" 
            type="password" 
            id="password" 
            placeholder="Your password"
            value={password} 
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <br />
          <br />
          <Button 
            variant="rounded" 
            type="submit" 
            className="login__button"
          >Log In</Button><br />
          <br/>
          {errorMessage ? <p style={{color: 'red'}}>{errorMessage}</p> : loadingDiv}
        </form>
        <br/>
        <p>Do not have an account? <a href="https://buy.stripe.com/4gw3ei5tW1VjeJ27ss">Purchase a subscription here</a>.</p>
        <p>Forgot password? <a href="mailto:support@timingsolutionmobile.com">Email Us</a>.</p>
        <p>Need help? <NavLink 
          to='/help' 
          key='logoLink01' 
          >
            Guide available here
        </NavLink>.</p>
      </div>
    </div>
  );
}

export default Login;