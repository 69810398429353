import React, {useEffect} from 'react'
import video1 from '../../videos/Searching_Instruments.mp4'
import video2 from '../../videos/Browsing_Instruments.mp4'
import video3 from '../../videos/Inspecting_Instruments.mp4'

export default function AddingInstruments() {
  return (
    <>
        <h2>Searching and Adding Instruments</h2>
        <br />
        <h3>Overview</h3>
        <div>
            <p>
                TS Mobile offers daily price data for over 100,000 different instruments including stocks from all over the world, indices, futures and commodities, FOREX pairs, and thousands of cryptocurrencies. Luckily, finding an instrument you want to work with is simple with TS Mobile. This guide will show you how to quickly find instruments you like and keep them in your Favorites List for easy access in the future.
            </p>
            <br />
            <p> 
                Additionally, when you have an instrument as your Favorite, you will be able to run analysis on it using QSpectrum and Natural Cycles modules.
            </p>
        </div>
        <br />
        <h3>Searching For Instruments</h3>
        <div>
            <p>To Searh for a specific Instrument do the folowing:</p>
            <ul>
                <li>- Open 'Search Instrument' from the Menu in the top right corner</li>
                <li>- At the top of the new screen, enter either the Symbol or the Name of the instrument you are interested in</li>
                <li>- Results will show below</li>
            </ul>
            <br />
            <video controls autoPlay muted loop playsInline style={{width: '80%', alignSelf: 'center'}}>
                <source src={video1} type="video/mp4" />
            </video>
        </div>
        <br />
        <h3>Browsing Different Instruments</h3>
        <div>
            <p>You can also browse for different instruments based on their Country, Sector or Industry. Controls for that are located to the right of the search field</p>
            <p>NOTE: If you want to work with FOREX, Indexes, Crypto, or Futures/Commodities, look for these in the SECTOR field.</p>
            <br />
            <ul>
                <li>- You can select Country via the 'Country' Dropdown at the top right</li>
                <li>- Sector list allows you to select different Sectors for stocks. This list also includes Non-Sector related categories such as Indexes, FOREX, Crypto, Futures & Commodities</li>
                <li>- Industry selection depends on your choice of a Sector. If Sector selection is set to 'All' or any non-sector related category is chosen, then Industry field will be empty</li>
                <li>- You can filter the instruments by price in the top right corner. If you enter 0 in both fields, the results will be shown without filtering by price</li>
                <li>- Each result shows the latest price for your quick reference</li>
                <li>- Instruments that are part of your Favorite List will have a green 'Favorite' by their name</li>
            </ul>
            <br />
            <video controls autoPlay muted loop playsInline style={{width: '80%', alignSelf: 'center'}}>
                <source src={video2} type="video/mp4" />
            </video>
        </div>
        <br />
        <h3>Inspecting Instruments</h3>
        <div>
            <p>Press on the "+" icon by an instrument to add it to your Favorite list (as shown in the two examples above). Sometimes you may want to take a closer look at the instrument, before adding it.</p>
            <p>To do so, click on the instrument name instead of the '+' icon. The latest price history along with some additional information will be shown.</p>
            <p>To go back to your search results, press the 'Back to Results' area on the top left corner.</p>
            <p>Alternatively you can press back on your browser. It will take you back to the search results</p>
            <br />
            <video controls autoPlay muted loop playsInline style={{width: '80%', alignSelf: 'center'}}>
                <source src={video3} type="video/mp4" />
            </video>
        </div>
    </>
  )
}
