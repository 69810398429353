import React from 'react'

import video1 from '../../videos/QSpectrum_Start.mp4'
import periodogram from '../../images/Periodogram.png'

export default function QSpectrumHelp() {
  return (
    <>
        <h2>QSpectrum Analysis</h2>
        <br />
        <h3>Overview</h3>
        <div>
          <p>QSpectrum is a unique feature of Timing Solution. It is based on the same concept of finding cyclical patterns in the price data of an instrument, but this one uses a special technique called Spectral Analysis.</p>
          <p>QSpectrum allows you to find cycles that are unique for this particular instrument. Unlike Predictable Zones and Intermarket, this feature is calculated on-demand for each instrument.</p>
        </div>
        <br />
        <h3>Getting Started With QSpectrum</h3>
        <div>
          <ul>
            <li>- On the Instrument Screen, click 'Add Model'</li>
            <li>- In the new dialog, select 'Spectrum Analysis'</li>
            <li>- QSpectrum window will appear and a base model will be calculated</li>
            <li>- Adjust model parameters on the right (Changing Kind or Stock Memory will require you to Generate Periodogram before continuing)</li>
            <li>- List of most prevalent cycles will be shown on the right</li>
            <li>- Click 'Submit' to add this model to your instrument</li>
          </ul>
          <br />
          <video controls autoPlay muted loop playsInline style={{width: '80%', alignSelf: 'center'}}>
              <source src={video1} type="video/mp4" />
          </video>
        </div>
        <br />
        <h3>QSpectrum Window Overview</h3>
        <div>
          <br />
          <p>When you open QSpectrum for the first time, it takes a moment to load all the required data for it</p>
          <p>After that, there is a lot of information presented to you. We will take a closer look at each area and how to work with it</p>
          <br />
          <h4>Periodogram</h4>
          <img src={periodogram} />
          <p>The chart at the top is a Periodogram. This represents the relative strength of each calculated cycle. If an instrument has a strong cycle, it's behavior is closely related to that cycle.</p> 
          <p>This is what we want to find - strong cycles that give more certainty in how this instrument might behave in the future.</p>
          <br />
          <p>On this chart x-axis represents the length of a cycle and y-axis represents its strength.</p>
          <p>There are two rules to follow when looking at this chart:</p>
          <ul>
            <li>1. Ideally we want very high peaks that stand out. This indicates a very strong cycle for the instrument.</li>
            <li>2. Avoid equally strong cycles that are close together</li>
          </ul>
          <p>In the example above, the strongest cycle is the 194.4 day cycle. It stands apart from others and there are no cycles nearby with similar strength.</p>
          <br />
          <h4>Spectrum Settings</h4>
          <p>On the left hand side you are able to modify the QSpectrum model settings.</p>
          <p>Modifying items in the rounded box will require you to re-calculate the Periodogram</p>
          <p>Lets take a look at each of the parameters a little closer.</p>
          <br />
          <h5>Kind</h5>
          <p>This selects what analysis algorithm to use. There are three to select from: 'Classic', 'Multi' and 'QSpectrum'.</p>
          <p>QSpectrum provides more precise results but takes longer to calculate.</p>
          <h5>Stock Memory</h5>
          <p>This chooses how much price chart data we need to reveal the presence of some cycle. 12 years is selected by default.</p>
          <h5>Sample Size</h5>
          <p>This has to do with testing each cycle against the actual price history. The higher this value is the longer it will take to do the analysis. 30 is selected by default.</p>
          <h5>Overtones</h5>
          <p>This sets how much detail will be presented on the model projection line. Too little and the curve does not give enough information. Too much and the curve will have unnecessary noise that is impractical for analysis.</p>
          <p>Setting of 4 is set by default as it offers best balance.</p>
          <h5>FSM</h5>
          <p>Stands for Forecast Stock Memory. It shows the number of cycles in the past that are used to calculate or confirm the cycle. 30 is set by default.</p>
          <br />
          <h4>Cycles</h4>
          <p>On the right side you will see the 10 strongest cycles TS Mobile has found. The strongest out of the 10 are at the top, with the first two cycles selected.</p>
          <p>When you click the checkmark by each cycle, you are telling TS Mobile to make a projection line based on these selected cycles. Projection line is the final forecast that this model provides and what you will see as the final result.</p>
          <br />
          <p>At the bottom of this dialog you have buttons to do the following:</p>
          <p><b>Remove</b> - deletes this model for the instrument</p>
          <p><b>Cancel</b> - closes QSpectrum Dialog. You can also click out side of the dialog to close it</p>
          <p><b>Submit</b> - Takes the final model settings and creates a projection line for your further analysis</p>
        </div>
    </>
  )
}
