import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {nodeUrl} from '../api/common'

export default function Admin() {
    // State Definitions
    const [inputState, setInputState] = useState(0)
    const [firstLastName, setFirstLastName] = useState('')
    const [email, setEmail] = useState('')
    const [sendEmail, setSendEmail] = useState(true)
    const [password, setPassword] = useState('')
    const [date, setDate] = useState('')
    const [type, setType] = useState('')
    const code = 'BsgIfdDiBKogTWd0r6kh'
    const [response, setResponse] = useState('')
    const [days, setDays] = useState(30)

    useEffect(() => {

        return () => {
            setInputState(-1)
        }

    }, [])

    const sendRequest = async (object, address) => {
        const requestObject = {...object, code}
        try {
            const response = await axios.post(`${nodeUrl}${address}`, requestObject)

            let responseString = JSON.stringify(response.data)
            if (responseString.startsWith('[')) {
                responseString = responseString.substring(1, responseString.length-1)
            }
            responseString = responseString.replaceAll('},{','}\n\n{')
            responseString = responseString.replaceAll(',','\n')
            setResponse(responseString)
        } catch (e) {
            try {
                setResponse(e.response.data)
            } catch {
                setResponse(e.message)
            }
        }
        // if (response.)
        // setResponse(JSON.stringify(response.data))
    }
    // -------------
    // Handlers
    const setInputStateHandler = (input) => {
        setInputState(input)
    }

    const createUserHandler = (e) => {
        e.preventDefault()
        // Generate 20-letter long random password
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let counter = 0
        let result = ''
        while (counter < 20) {
            result += characters.charAt(Math.floor(Math.random()*(characters.length)))
            counter += 1
        }

        const requestObject = {
            name: firstLastName,
            email,
            password: result,
            sendEmail
        }
        sendRequest(requestObject, '/node/user/create')
    }

    const readUserHandler = (e) => {
        e.preventDefault()
        const requestObject = {
            email
        }
        sendRequest(requestObject, '/node/user/getUser')
    }

    const readAllUsersHandler = (e) => {
        e.preventDefault()
        sendRequest({}, '/node/user/getUser')
    }

    const resetPasswordHandler = (e) => {
        e.preventDefault()
        const requestObject = {
            email,
            newPassword: password
        }
        sendRequest(requestObject, '/node/user/resetUserPassword')
    }

    const activateUserHandler = (e) => {
        e.preventDefault()
        const requestObject = {
            email,
            expire: date
        }
        sendRequest(requestObject, '/node/stripe/TSActivateCustomer')
    }

    const deactivateUserHandler = (e) => {
        e.preventDefault()
        const requestObject = {
            email
        }
        sendRequest(requestObject, '/node/stripe/TSDeactivateCustomer')
    }

    const changeExpireDateHandler = (e) => {
        e.preventDefault()
        const requestObject = {
            email,
            expireAt: date
        }
        sendRequest(requestObject, '/node/stripe/TSChangeExpireDate')
    }

    const addExpectedUserHandler = (e) => {
        e.preventDefault()
        const requestObject = {
            email,
            type
        }
        sendRequest(requestObject, '/node/stripe/TSExpectUser')
    }

    const removeExpectedUserHandler = (e) => {
        e.preventDefault()
        const requestObject = {
            email
        }
        sendRequest(requestObject, '/node/stripe/removeCustomer')
    }

    const checkRecentUsersHandler = (e) => {
        e.preventDefault()
        const requestObject = {
            days: days
        }
        sendRequest(requestObject, '/node/user/checkRecentUsers')
    }
    // ------------
    // Pre-render
    // ------------
    // Pre-render fucntions
    const renderCreateInput = () => {
        return <form onSubmit={createUserHandler}>
            <label>
                First and Last Names:
                <input type='text' name='name' value={firstLastName} onChange={e => setFirstLastName(e.target.value)} />
            </label>
            <label>
                Email:
                <input type='email' name='email' value={email} onChange={e => setEmail(e.target.value)} />
            </label>
            <label>
                Send Email?
                <input type='checkbox' checked={sendEmail} onChange={() => setSendEmail(!sendEmail)} />
            </label>
            <input type='submit' value='Submit' />
        </form>
    }

    const renderReadUsers = () => {
        return <form onSubmit={readUserHandler}>
            <label>
                Email:
                <input type='text' name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
            </label>
            <input type='submit' name='getUser' value='Read User' />
            <input type='button' name='getAll' value='Get ALL Users' onClick={readAllUsersHandler} />
        </form>
    }

    const renderResetPassword = () => {
        return <form onSubmit={resetPasswordHandler}>
            <label>
                User Email:
                <input type='email' name='email' value={email} onChange={e => setEmail(e.target.value)} />
            </label>
            <label>
                New Password:
                <input type='password' name='password' value={password} onChange={e => setPassword(e.target.value)} />
            </label>
            <input type='submit' value='Reset Password' />
        </form>
    }

    const renderActivateUser = () => {
        return <form onSubmit={activateUserHandler}>
            <label>
                User Email:
                <input type='email' name='email' value={email} onChange={e => setEmail(e.target.value)} />
            </label>
            <label>
                Expiration Date:
                <input type='text' name='text' placeholder='e.g. Aug-06-2022' value={date} onChange={e => setDate(e.target.value)} />
            </label>
            <input type='submit' value='Activate' />
        </form>
    }

    const renderDeactivateUser = () => {
        return <form onSubmit={deactivateUserHandler}>
            <label>
                User Email:
                <input type='email' name='email' value={email} onChange={e => setEmail(e.target.value)} />
            </label>
            <input type='submit' value='Deactivate' />
        </form>
    }

    const renderChangeExpireDate = () => {
        return <form onSubmit={changeExpireDateHandler}>
            <label>
                User Email:
                <input type='email' name='email' value={email} onChange={e => setEmail(e.target.value)} />
            </label>
            <label>
                Expiration Date:
                <input type='text' name='text' placeholder='e.g. Aug-06-2022' value={date} onChange={e => setDate(e.target.value)} />
            </label>
            <input type='submit' value='Activate' />
        </form>
    }

    const renderAddExpectedUser = () => {
        return <form onSubmit={addExpectedUserHandler}>
            <label>
                User Email:
                <input type='email' name='email' value={email} onChange={e => setEmail(e.target.value)} />
            </label>
            <label>
                Type:
                <input type='text' name='text' value={type} onChange={e => setType(e.target.value)} />
            </label>
            <p>Options are:</p>
            <p>Terra</p>
            <p>Scanner</p>
            <p>Primo</p>
            <p>Adv</p>
            <p>ScannerYear</p>
            <input type='submit' value='Add' />
        </form>
    }

    const renderRemoveExpectedUser = () => {
        return <form onSubmit={removeExpectedUserHandler}>
            <label>
                User Email:
                <input type='email' name='email' value={email} onChange={e => setEmail(e.target.value)} />
            </label>
            <input type='submit' value='Remove Expected User' />
        </form>
    }

    const renderCheckRecentUsers = () => {
        return <form onSubmit={checkRecentUsersHandler}>
            <label>
                Enter days:
                <input type='number' name='days' value={days} onChange={e => setDays(e.target.value)} />
            </label>
            <input type='submit' value='Check Users' />
        </form>
    }



    // Pre-render calls
    let inputDiv
    switch (inputState) {
        case 0:
            inputDiv = renderCreateInput()
            break
        case 1:
            inputDiv = renderReadUsers()
            break
        case 2:
            inputDiv = renderResetPassword()
            break
        case 3:
            inputDiv = renderActivateUser()
            break
        case 4:
            inputDiv = renderDeactivateUser()
            break
        case 5:
            inputDiv = renderChangeExpireDate()
            break    
        case 6:
            inputDiv = renderAddExpectedUser()
            break    
        case 7:
            inputDiv = renderRemoveExpectedUser()
            break
        case 8:
            inputDiv = renderCheckRecentUsers()
            break
        default:
            break
    }

  return (
    <div className='admin'>
        <div className='admin__options'>
            <div>Options</div>
            <button 
            className='admin__button'
            onClick={() => setInputStateHandler(0)}
            >
                Create User
            </button>
            <button 
            className='admin__button'
            onClick={() => setInputStateHandler(1)}
            >
                Read Users
            </button>
            <button  
            className='admin__button'
            onClick={() => setInputStateHandler(2)}
            >
                Reset User Password
            </button>
            <button  
            className='admin__button'
            onClick={() => setInputStateHandler(3)}
            >
                Activate User
            </button>
            <button  
            className='admin__button'
            onClick={() => setInputStateHandler(4)}
            >
                Deactivate User
            </button>
            <button  
            className='admin__button'
            onClick={() => setInputStateHandler(5)}
            >
                Change Expire Date
            </button>
            <button  
            className='admin__button'
            onClick={() => setInputStateHandler(6)}
            >
                Add Expected User (Discount)
            </button>
            <button  
            className='admin__button'
            onClick={() => setInputStateHandler(7)}
            >
                Remove Expected User
            </button>
            <button 
                className='admin__button'
                onClick={() => setInputStateHandler(8)}
            >
                Check Recent Users
            </button>
        </div>
        <div className='admin__io'>
            <div className='admin__input'>
                <p>Input</p>
                <p>{inputDiv}</p>
            </div>
            <div className='admin__output'>
                <textarea 
                    value={response}
                    
                />
            </div>
        </div>
    </div>
  )
}
