import React from 'react'
import video1 from '../../videos/Intermarket.mp4'

export default function IntermarketHelp() {
  return (
    <>
        <h2>Intermarket Analysis</h2>
        <br />
        <h3>Overview</h3>
        <div>
          <p>Intermarket Analysis is based on the idea that there are correlations between different asset classes.</p>
          <p>TS Mobile takes this idea one step further. We have analyzed over 100000 symbols to see if each symbol has any sort of relationship to any other ones.</p>
          <p>This analysis is ongoing and the results of it are constantly updated.</p>
        </div>
        <h3>Using Intermarket Analysis</h3>
        <div>
          <p>Intermarket Results are shown when you view an instrument in detail.</p>
          <p>Above the price chart you will see 'INTERMARKET ANALYSIS:...' area in yellow. This shows if there is any instrument whose behavior is similar to that of your selected instrument.</p>
          <br />
          <ul>
            <li>- Click on the 'INTERMARKET ANALYSIS' above the price chart of your instrument.</li>
            <li>- In the new window, you will see a list of all the instruments that are related to your current instrument.</li>
            <li>- On the left is a list of all the Leading indicators, along with Instrument Symbol, Lag, and whether the behavior is inverted or not.</li>
            <li>- On the right there is more information about the correlation. We also display what was the similarity of the two instruments in the last year, and the overall similarity between the two instruments.</li>
            <li>- Check the <b>Show on main chart</b> checkbox to have the Leading Indicator chart show on the main Price Chart of your Instrument.</li>
          </ul>
          <br />
          <video controls autoPlay muted loop playsInline style={{width: '80%', alignSelf: 'center'}}>
              <source src={video1} type="video/mp4" />
          </video>
        </div>
    </>
  )
}
