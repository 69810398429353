import React, { useState, useContext, useEffect, useRef } from 'react'
import { addDays } from 'date-fns'
import moment from 'moment'
import Button from '../components/Button'
import {NavLink} from 'react-router-dom'
import DataContext from '../context/DataContext'
import useErrorHandler from '../hooks/useErrorHandler'
import useContextCommunicator from '../hooks/useContextCommunicator'
import {TbArrowBarUp, TbArrowBarDown} from 'react-icons/tb'

export default function StockBrowser() {
    // context defs
    const {scannerState, scanForStocks, setInstrumentShown, categories, countries, setVisitedFrom, updateFromPrice, updateToPrice, scannerFromPrice, scannerToPrice, scannerTypeFilter, scannerTypeOrder, updateType, updateOrder} = useContext(DataContext)
    const {errorHandler} = useErrorHandler()
    const {quickAddInstrumentCommand} = useContextCommunicator()
    // state defs
    const [start, setStart] = useState(moment(new Date()).format('yyyy-MM-DD'))
    const [end, setEnd] = useState(moment(addDays(new Date(), 7)).format('yyyy-MM-DD'))
    const [country, setCountry] = useState("USA")
    const [countryDisabled, toggleCountry] = useState(false)
    const [category, setCategory] = useState('Financial Services')
    const [searchType, setSearchType] = useState('EntryExit')
    const [tradeLength, setTradeLength] = useState("15")
    const [scannedStocks, setScannedStocks] = useState(scannerState.results)
    const [errorMessage, setError] = useState('')
    const [searchSubmitted, toggleSearch] = useState(false)
    const [requestInProgress, setRequestInProgress] = useState(false)
    const [goToPage, setGoToPage] = useState(1)
    const [sortType, setSortType] = useState(0)
    const [searchParamsHidden, setSearchParamsHidden] = useState(true)
    const [searchDisabled, toggleSearchDisabled] = useState(false)

    // Helper functions
    const organizeResults = (results=[]) => {
        let newState

        if (results.length) {
            newState = results
        } else {
            newState = [...scannedStocks]   
        }

        newState.sort(function(a,b) {
            if (scannerTypeOrder === '-') {
                if (scannerTypeFilter === 'Avg') {
                    return b.average - a.average
                } else if (scannerTypeFilter === 'Med') {
                    return b.median - a.median
                }
            } else if (scannerTypeOrder === '+') {
                if (scannerTypeFilter === 'Avg') {
                    return a.average - b.average
                } else if (scannerTypeFilter === 'Med') {
                    return a.median - b.median
                }
            }
        })

        if (results.length) {
            return newState
        } else {
            setScannedStocks(newState)
        }
    }

    const filterPriceHandler = () => {
        // Validation
        if (scannerToPrice < scannerFromPrice) {
            return alert("Price range incorrect. From Price must be lower than To Price")
        }

        const filterResults = (array, from, to) => {
            return array.filter(el => {
                const price = parseFloat(el.lastPrice)
                if ((price >= from) && (price <= to)) {
                    return true
                }
            })
        }

        let filteredResults
        if ((scannerToPrice == 0) && (scannerFromPrice == 0)) {
            setScannedStocks(scannerState.results)
        } else {
            
            filteredResults = filterResults(scannerState.results, scannerFromPrice, scannerToPrice)
            setScannedStocks(filteredResults)
        }
    }

    // First Init
    useEffect(() => {
        if (scannerState.results.length>0) {
            setStart(scannerState.params.dateRange[0])
            setEnd(scannerState.params.dateRange[1])
            setCountry(scannerState.params.country)
            setCategory(scannerState.params.category)
            toggleSearch(true)
            organizeResults(scannerState.results)
            filterPriceHandler()
            // organizeResults()
        } else if (scannerState.params.country && scannerState.params.country !== country) {
            setCountry(scannerState.params.country)
        }
    }, [scannerState])


    // handlers
    const scannerSubmit = async (page) => {
        // validation
        if (start > end) {
            return setError('End date cannot be before Start date. Select another range with End date after Start date')
        }

        if (tradeLength <= 0) {
            return setError('Cannot have trade length less than 1 day')
        }
        setError('')

        let countrySubmit = country
        let categorySubmit = category
        
        if (countryDisabled) {
            countrySubmit = 'None'
        } else if (country === 'United Kingdom') {
            countrySubmit = 'UK'
        }

        // request
        try {
            const dateRange = [start, end]
            setRequestInProgress(true)
            setScannedStocks([])
            const results = await scanForStocks(dateRange, categorySubmit, countrySubmit, searchType, tradeLength, page, sortType)
            const orderedResults = organizeResults(results.results)
            setScannedStocks(orderedResults)
            toggleSearch(true)
            setRequestInProgress(false)
            toggleSearchDisabled(true)
        } catch (e) {
            errorHandler(e)
        }
    }

    const setCategoryHandler = (e) => {
        const cat = e.target.value
        if (cat === 'Indexes' || cat === 'International Indexes' || cat === 'Commodities' || cat === 'Treasury' || cat === 'Forex' || cat === 'Futures' || cat === 'Crypto') {
            toggleCountry(true)
        } else {
            toggleCountry(false)
        }
        toggleSearchDisabled(false)
        setCategory(cat)
    }

    const setCountryHandler = (e) => {
        toggleSearchDisabled(false)
        setCountry(e.target.value)
    }

    const linkClicked = (item) => {
        setInstrumentShown(item)
        setVisitedFrom('/scanner')
    }

    const quickAddInstrument = (item) => {
        quickAddInstrumentCommand(item)
    }

    const backHandler = async () => {
        const newPage = parseInt(goToPage) - 1
        setGoToPage(newPage)
        scannerSubmit(newPage)
    }
    const nextHandler = async () => {
        const newPage = parseInt(goToPage) + 1
        setGoToPage(newPage)
        scannerSubmit(newPage)
    }
    const scannerSubmitHandler = () => {
        setGoToPage(1)
        scannerSubmit(1)
    }

    const setSortHandler = async (type) => {
        setScannedStocks([])
        let countrySubmit = country
        let categorySubmit = category
        
        if (countryDisabled) {
            countrySubmit = 'None'
        } else if (country === 'United Kingdom') {
            countrySubmit = 'UK'
        }

        const dateRange = [start, end]
        const results = await scanForStocks(dateRange, categorySubmit, countrySubmit, searchType, tradeLength, 1, type)
        setScannedStocks(results.results)
        setSortType(type)
    }

    // ----------------- Pre-render -----------------
    let scannerDiv = null
    const categoryList = categories.map(item => {
        return (
            <option key={`category${item}`} value={item}>{item}</option>
        )
    })

    const countryList = countries.map(item => {
        return (
            <option key={`country${item}`} value={item}>{item}</option>
        )
    })

    // ****************************************
    let paginationDiv
    // if (scannedStocks.totalPages > 1) {
    //     paginationDiv = <div>
    //         Found: {scannedStocks.totalResults} results. Total Pages: {scannedStocks.totalPages}
    //         <div>
    //             Showing page: {scannedStocks.page}
    //         </div>
    //         <div>
    //             Go to page: <input 
    //                 type='number'
    //                 placeholder={scannedStocks.page}
    //                 value={goToPage}
    //                 style={{width: '4rem'}}
    //                 onChange={(e) => setGoToPage(parseInt(e.target.value))}
    //             />
    //             <button onClick={() => scannerSubmit(goToPage)}>Go to page</button>
    //         </div>
    //         <div>
    //             {scannedStocks.page === 1 ? null : <button onClick={backHandler}>Go Back</button>}
    //             <button onClick={nextHandler}>Next Page</button>
    //         </div>
    //     </div>
    // }
    // ******************************************

    let scanResults
    if (scannedStocks && scannedStocks.length) {
        const stocksDiv = scannedStocks.map(item => {
            if (item.relevance === 1 || item.relevance === 2) {
                let itemClass = ""
                if (item.trend === "UP") {
                    itemClass = "stockScanner--search-results__result stockScanner--search-results__result--green"
                } else if (item.trend === "DOWN") {
                    itemClass = "stockScanner--search-results__result stockScanner--search-results__result--red"
                } else {
                    itemClass = "stockScanner--search-results__result"
                }
                let NavLinkObj
                if (item.name) {
                    NavLinkObj = (
                        <NavLink to='/instrument' onClick={() => linkClicked(item.instrument)} className="stockScanner--search-results__result--description">
                            {/* <div className="stockScanner--search-results__result--add" onClick={() => quickAddInstrument(item.instrument)}></div> */}
                            <div>{item.name} - {item.range[0]} to {item.range[1]}{item.ups} UP/{item.downs} DOWN. Last Price: {item.lastPrice}</div>
                            <div>{item.ups} UP/{item.downs} DOWN - Average Change: {item.average}%. Median: {item.median}%</div>
                        </NavLink>
                    )
                } else {
                    NavLinkObj = (
                        <NavLink to='/instrument' onClick={() => linkClicked(item.symbol)} className="stockScanner--search-results__result--description">
                            <div>{item.symbol}/{item.instrument}{item.sector? " - " + item.sector+": "+item.industry : null} - {item.range[0]} to {item.range[1]}. Last Price: {item.lastPrice}</div>
                            <div>{item.ups} UP/{item.downs} DOWN - Average Change: {item.average}%. Median: {item.median}%</div>
                        </NavLink>
                    )
                }

                let stockSymbol
                if (item.name) {
                    stockSymbol = item.instrument
                } else {
                    stockSymbol = item.symbol
                }

                return (
                    <div key={`result_${item.symbol}`} className={itemClass}>
                        <div className="stockScanner--search-results__result--add" onClick={() => quickAddInstrument(stockSymbol)}></div>
                        {NavLinkObj}
                    </div>
                )
            } else {
                return null
            }
        })

        scanResults = (
            <div className="stockScanner--search-results">
                <p>Found {scannedStocks.length} Most Relevant Stocks</p>
                {paginationDiv}
                {stocksDiv}
            </div>
        )
    } else {
        scanResults = (
            <div className="stockScanner--search-results">
                {requestInProgress? <p style={{fontWeight:'bold'}}>Searching. Please wait...</p> : <p style={{fontWeight:'bold', color:'red'}}>No Results</p>}
                
            </div>
        )
    }
    // Screen load
    if (searchSubmitted) {
        // results screen
        // search button definition
        let searchButton = <Button className='button__search' onClick={scannerSubmitHandler}>Search</Button>
        if (requestInProgress) {
            searchButton = <Button className='button__search' disabled>Searching...</Button>
        } else if (searchDisabled) {
            searchButton = <Button className='button__search' disabled>Search</Button>
        }

        scannerDiv = (
            <React.Fragment>
            <div className="stockScanner--search-params">
                <h4>Scan Parameters</h4>
                <div className='stockScanner--search-params__row' style={{
                        opacity: searchParamsHidden ? "0" : "1",
                        transition: "all .5s",
                        visibility: searchParamsHidden ? "hidden" : "visible",
                        height: searchParamsHidden ? '0' : 'auto',
                        }}>
                    <div className='stockScanner--search-params__column'>
                        {/* Trade Range */}
                        <div className='stockScanner--search-params__option'>
                            <div>Trade Range</div>
                            <span>From: </span>
                            <input type='date' value={start} onChange={(e) => {setStart(e.target.value); toggleSearchDisabled(false)}} />
                            <span>To: </span>
                            <input type='date' value={end} onChange={(e) => {setEnd(e.target.value); toggleSearchDisabled(false)}} />
                        </div>
                        {/* Sector */}
                        <div className='stockScanner--search-params__option'>
                            <div>Sector/Type:</div>
                            <select value={category} onChange={setCategoryHandler}>
                                <option value="All">All</option>
                                {categoryList}
                            </select>
                        </div>
                        {/* Country */}
                        <div className='stockScanner--search-params__option'>
                            <div>Country:</div>
                            <select value={country} onChange={setCountryHandler} disabled={countryDisabled}>
                                <option value="All">All</option>
                                {countryList}
                            </select>
                        </div>
                    </div>
                    <div className='stockScanner--search-params__column'>
                        {/* Search Type */}
                        <div className='stockScanner--search-params__option'>
                            <div>Search Type:</div>
                            <select value={searchType} onChange={(e) => {setSearchType(e.target.value); toggleSearchDisabled(false)}}>
                                <option value='EntryExit'>By Entry and Exit dates</option>
                                <option value='EntryOnly'>Entry within set period</option>
                                <option value='All'>All zones within set period</option>
                            </select>
                        </div>
                        {/* Trade Length */}
                        <div className='stockScanner--search-params__option'>
                            <div>Max Trade length</div>
                            <div>
                            <input type="integer" disabled={searchType !== "EntryOnly"} value={tradeLength} onChange={(e) => {setTradeLength(e.target.value); toggleSearchDisabled(false)}} />
                            Days
                            </div>
                        </div>
                        {/* Sorting Method
                        <div className='stockScanner--search-params__option'>
                            <div>Sort by:</div>
                            <select value={sortType} onChange={(e) => setSortHandler(e.target.value)}>
                                <option value={0}>Closest to selected dates</option>
                                <option value={1}>UP Trend strength</option>
                                <option value={2}>DOWN Trend strength</option>
                            </select>
                        </div> */}
                        {/* Search Button */}
                        {searchButton}
                    </div>
                </div>
                <div className='stockScanner--search-params__row' style={{justifyContent: 'center'}}>
                    {searchParamsHidden ?
                    <TbArrowBarDown size="24" color="#333" onClick={() => setSearchParamsHidden(false)} style={{cursor: 'pointer'}} />
                    :
                    <TbArrowBarUp size="36" color="#333" onClick={() => setSearchParamsHidden(true)} style={{cursor: 'pointer'}} />
                    }
                </div>
                <hr />
                <h4>Sorting Options</h4>
                <div className='stockScanner--search-params__row'>
                    <div className='stockScanner--search-params__column'>
                        {/* Price Filter */}
                        <div className='stockScanner--search-params__option stockScanner--search-params__option--small'>
                            <span>Price Range</span>
                            <span>Setting both values to 0 will show all results</span>
                            <div>
                                <span>From:</span>
                                <input 
                                    type='number' 
                                    value={scannerFromPrice}
                                    onChange={(e) => updateFromPrice(e.target.value)}
                                    style={{maxWidth: '5rem'}}
                                />
                                <span> To: </span>
                                <input 
                                    type='number' 
                                    value={scannerToPrice}
                                    onChange={(e) => updateToPrice(e.target.value)}
                                    style={{maxWidth: '5rem'}}
                                />
                            </div>
                            <Button
                                className='button__search'
                                onClick={filterPriceHandler}
                            >
                                Filter
                            </Button>
                        </div>
                    </div>
                    <div className='stockScanner--search-params__column'>
                        {/* Order_by */}
                        <div className='stockScanner--search-params__option stockScanner--search-params__option--small'>
                            <span>Sort By:</span>
                            <span>&nbsp;</span>
                            <div>
                                <span>Type: </span>
                                <select value={scannerTypeFilter} onChange={(e) => updateType(e.target.value)}>
                                    <option value='Avg'>Average</option>
                                    <option value='Med'>Median</option>
                                </select>
                                <span>Order: </span>
                                <select value={scannerTypeOrder} onChange={(e) => updateOrder(e.target.value)}>
                                    <option value='+'>Ascending</option>
                                    <option value='-'>Descending</option>
                                </select>
                            </div>
                            <Button
                                className='button__search'
                                onClick={organizeResults}
                            >
                                Sort
                            </Button>
                        </div>
                    </div>
                </div>
                <div>{errorMessage? <span style={{color:'red', fontWeight:'normal', textTransform:'none'}}>{errorMessage}</span> : null}</div>
            </div>
            {scanResults}
            </React.Fragment>
        )
    } else {
        // console.log(scannerState)
        // default screen
        scannerDiv = (<React.Fragment>
            <div className="stockScanner--center">
                <h2>Let's find what to trade</h2>
                {errorMessage? <p style={{color:'red'}}>{errorMessage}</p> : <p className='stockScanner--center__label'>What dates do you want to trade?</p>}
                <div className='stockScanner--center__bar'>
                    {/* <div> */}
                        <div className='stockScanner--center__item'>
                            <span>From: </span>
                            <input type='date' value={start} onChange={(e) => setStart(e.target.value)} />
                        </div>
                    {/* </div> */}
                    <div className='stockScanner--center__item'>
                        <span>To: </span>
                        <input type='date' value={end} onChange={(e) => setEnd(e.target.value)} min={start} />
                    </div>
                    <div className='stockScanner--center__item'>
                        <span>
                            Sector/Type: 
                        </span>
                        <select value={category} onChange={setCategoryHandler}>
                            <option key='categoryAll' value="All">All</option>
                            {categoryList}
                        </select>
                    </div>
                    <div className='stockScanner--center__item'>
                        <span>
                            Country:
                        </span>    
                        <select value={country} onChange={setCountryHandler} disabled={countryDisabled}>
                            <option key='countryAll' value="All">All</option>
                            {countryList}
                        </select>
                    </div>
                    <div className='stockScanner--center__item'>
                        <span>
                            Search Type:
                        </span>
                        <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
                            <option key='entryExit' value='EntryExit'>By Entry and Exit dates</option>
                            <option key='entryOnly' value='EntryOnly'>Entry within set period</option>
                            <option key='entryAll' value='All'>All zones within set period</option>
                        </select>
                    </div>
                    <div className='stockScanner--center__item'>
                        <div>
                            <span>
                                Max Trade length:
                            </span>
                        </div>
                        
                        <div>
                            <div>
                                <input type="integer" style={{width: "3rem"}} disabled={searchType !== "EntryOnly"} value={tradeLength} onChange={(e) => setTradeLength(e.target.value)} />
                                Days
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginBottom: '2rem'}}>
                    Max Trade Length is only available if you wish to enter a trade between "From" and "To" dates.
                    <br/>
                    Select Search Type to "Entry within set period" to define Max Trade Length.
                </div>
                {requestInProgress ?<Button className='button__search button__search--large' disabled >Searching...</Button> : <Button className='button__search button__search--large' onClick={() => scannerSubmit(1)} >Find</Button> }
                <div className='stockScanner--center__bottom'>
                    <br />
                    <p>Enter the planned dates you wish to start and end the trade.</p>
                    <p>After clicking the "Find" button, we will find instruments that fit your search criteria and have performed reliably in the last 12 years.</p>
                </div>
                <div className="stockScanner--hidden"><span>Photo by <a href="https://unsplash.com/@eiskonen?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Hans Eiskonen</a> on <a href="https://unsplash.com/s/photos/stock-market?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span></div>
            </div>
        </React.Fragment>)
    }

// ----------------------------------------------------------
    
// </div>
// <div style={{textAlign: 'center'}}>
// {requestInProgress? <Button className='button__search' disabled>Searching...</Button> : <Button className='button__search' onClick={scannerSubmitHandler}>Search</Button>}
// </div>

    return (
        <div className='stockScanner'>
            {scannerDiv}
        </div>
    )
}