import React, {useState} from 'react'

import Overview from '../components/Help/Overview'
import AddingInstruments from '../components/Help/AddingInstruments'
import ScannerHelp from '../components/Help/ScannerHelp'
import IntermarketHelp from '../components/Help/IntermarketHelp'
import QSpectrumHelp from '../components/Help/QSpectrumHelp'
import AstroHelp from '../components/Help/AstroHelp'
import TSUsers from '../components/Help/TSUsers'
import InstrumentHelp from '../components/Help/InstrumentHelp'


export default function Help() {
    const [topic, showTopic] = useState(1)

    // HELP DIVS

    let content
    switch (topic) {
        case 2:
            content = <AddingInstruments />
            break;
        case 3:
            content = <ScannerHelp />
            break;
        case 4:
            content = <IntermarketHelp />
            break;
        case 5:
            content = <QSpectrumHelp />
            break;
        case 6:
            content = <AstroHelp />
            break;
        case 7:
            content = <InstrumentHelp />
            break;
        default:
            content = <Overview 
                clickedTo={(e) => {
                    showTopic(e)
                }}
            />
    }

  return (
    <div className='help'>
        <div className='help__topics'>
            <div onClick={() => showTopic(1)} className='help__topics--item'>
                Getting Started
            </div>
            <div onClick={() => showTopic(2)} className='help__topics--item'>
                Adding Instruments
            </div>
            <div onClick={() => showTopic(3)} className='help__topics--item'>
                Scanner and Predictable Zones
            </div>
            <div onClick={() => showTopic(7)} className='help__topics--item'>
                Instrument Page Overview
            </div>
            <div onClick={() => showTopic(4)} className='help__topics--item'>
                Intermarket Analysis
            </div>
            <div onClick={() => showTopic(5)} className='help__topics--item'>
                QSpectrum Analysis
            </div>
            <div onClick={() => showTopic(6)} className='help__topics--item'>
                Natural Cycle Analysis
            </div>
        </div>
        <div className='help__content'>
            {content}
        </div>
    </div>
  )
}
