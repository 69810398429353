import React, { useReducer } from 'react'
import authServer from '../api/authServer'
import dataServer from '../api/dataServer'
import {removeUserStock, addUserStock, updatePortfolios, addUserStockCustom} from '../utils/processor'

const UserContext = React.createContext();

const authReducer = (state, action) => {
    switch (action.type) {
        case 'loading_on':
            return {...state, loading: true}
        case 'login_success':
            return {
                loading: false,
                isAuth: true,
                name: action.payload.name,
                token: action.payload.token,
                stocks: action.payload.stocks,
                customStocks: action.payload.customStocks,
                portfolios: action.payload.portfolios,
                email: action.payload.email,
                error: ''
            }
        case 'logout':
            return {isAuth: false, name: '', token: '', loading: false, stocks: [], portfolios: [], email: '', error: ''}
        case 'remove_user':
            return {isAuth: false, name: '', token: '', loading: false, stocks: [], portfolios: [], error: 'Another session is active', email: '' }
        case 'stop_loading':
            return {...state, loading: false}
        case 'remove_stock':
            return removeUserStock(state, action.payload)
        case 'add_stock':
            return addUserStock(state, action.payload)
        case 'add_custom_stock':
            return addUserStockCustom(state, action.payload)
        case 'update_portfolios':
            return updatePortfolios(state, action.payload)
        default:
            return state;
    }
}

export let authHeader = {
    headers: {
        'Authorization': 'Bearer ',
    }
}

const generateHeader = (token) => {
    authHeader = {
        headers: {
            'Authorization': 'Bearer '+ token,
          }
    }
}

export const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {isAuth: false, name: '', token: '', loading: false, stocks: [], portfolios: [], error: ''})

    const attemptLogin = async ( email, password ) => {
        try {
            dispatch({ type: 'loading_on' })
            
            const response = await authServer.post('/loginContinue', { email, password })
            if (response.data.error) {
                return response.data
            }
            generateHeader(response.data.token)
            localStorage.setItem('token', response.data.token)
            await dispatch({ 
                type: 'login_success', 
                payload: {
                    name: response.data.name,
                    token: response.data.token, 
                    stocks: response.data.stocks,
                    customStocks: response.data.customStocks,
                    portfolios: response.data.portfolios,
                    country: response.data.country,
                    email
                }})
            return {stocks: response.data.stocks, portfolios: response.data.portfolios, customStocks: response.data.customStocks}
        } catch (err) {
            dispatch({ type: 'stop_loading' })
            throw new Error(err.response.data.error)
        }
    }

    const loginWithToken = async (token) => {
        try {
            const requestHeader = {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }
            const response = await authServer.post('/tokenCheck', {}, requestHeader)
            generateHeader(token)
            dispatch({ 
                type: 'login_success', 
                payload: {
                    name: response.data.name,
                    token: response.data.token, 
                    stocks: response.data.stocks,
                    customStocks: response.data.customStocks,
                    portfolios: response.data.portfolios,
                    country: response.data.country,
                    email: response.data.email
                }})
            return {stocks: response.data.stocks, portfolios: response.data.portfolios, customStocks: response.data.customStocks, country: response.data.country}
        } catch (err) {
            throw new Error(err.response.status)
            // localStorage.removeItem('token')
            // dispatch({ type: 'logout' })
        }
    }

    const logout = async () => {
        try {
            const response = await authServer.post('/logoutAll', {}, authHeader)
            if (response.data === 'Logged out') {
                localStorage.removeItem('token')
                dispatch({ type: 'logout' })
            }
        } catch (err) {
            // logged out off network but not in app
            dispatch({ type: 'logout' })
        }
    }

    const removeUserInstrument = (stock) => {
        dispatch({ type: 'remove_stock', payload: stock})
    }

    const addUserInstrument = (stock, type) => {
        if (type === 'standard') {
            dispatch({ type: 'add_stock', payload: stock})
        } else if (type === 'custom') {
            dispatch({ type: 'add_custom_stock', payload: stock})
        }
    }

    const addUserPortfolio = async (object) => {
        try {
            const response = await dataServer.post('/createPortfolio', object)
            if (typeof response.data === 'string') {
            } else {
                dispatch({ type: 'update_portfolios', payload: response.data})
            }
            return response.data
        } catch (err) {
            throw new Error(err.response.status)
        }
    }

    const updateUserPortfolios = (ports) => {
        dispatch({ type: 'update_portfolios', payload: ports})
    }

    const removeUser = () => {
        localStorage.removeItem('token')
        dispatch({ type: 'remove_user' })
    }

    return (
        <UserContext.Provider value={{
            isAuth: state.isAuth, loading: state.loading, token: state.token, stocks: state.stocks, customStocks: state.customStocks, portfolios: state.portfolios, error: state.error, username: state.name, email: state.email,
            attemptLogin, logout, loginWithToken, removeUserInstrument, addUserInstrument, addUserPortfolio, updateUserPortfolios, removeUser
        }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext