import React from 'react'

export default function LoadingBar() {
    return (
        <div className="loading-bar">
            <span className="loading-bar--bar">
                <span className="loading-bar--progress"></span>
            </span>
        </div>
    )
}
