import React, { useContext} from 'react';
import logo from '../TSOLogo1.png';
import UserContext from '../context/UserContext'
import {NavLink} from 'react-router-dom'

const Navigation = ({ menuClicked }) => {
  // Context Definition
  const {isAuth, username} = useContext(UserContext)
  
  // Pre-render
  const menuComponent = (
      <div onClick={menuClicked} style={{display: 'flex', flexDirection:'column', alignItems:'center', cursor: 'pointer'}}>
        <div className="navigation__menu--container">
            <div className="navigation__menu"></div>
        </div>
        <span>Menu</span>
      </div>
  )

  return (
    <React.Fragment>
      <nav className="navigation__bar">

        <NavLink 
          to='/' 
          key='logoLink01' 
          >
            <img src={logo} className="navigation__logo" alt="TSO Logo"></img>
        </NavLink>
        
        <div className="navigation__center">
          <h2 className="navigation__center--title">Timing Solution Mobile</h2>
          <span style={{textAlign:"center"}}>{username}</span>
        </div>
        {isAuth ? menuComponent : <div className="navigation__logout" />}
      </nav>
    </React.Fragment>
  );
}

export default Navigation;
