import React, { useReducer } from 'react'
// import authServer from '../api/authServer'
import dataServer from '../api/dataServer'
// import {removeUserStock, addUserStock, updatePortfolios, addUserStockCustom} from '../utils/processor'

const StorageContext = React.createContext();

const storageReducer = (state, action) => {
    switch (action.type) {
        case 'set_data':
            return action.payload
        default:
            return state;
    }
}

export const StorageProvider = ({ children }) => {
    const [state, dispatch] = useReducer(storageReducer, [])

    const downloadStockList = async () => {
        // Get list file from server
        const response = await dataServer.post('/listAllStocks')
        const stockArr = response.data.split('\r\n')
        stockArr.pop()

        // Sort into usable array
        const newArr = []
        if (stockArr.length) {
            stockArr.forEach(el => {
                if (el !== '') {
                    // Break down to stock, name, country
                    const elArr = el.split('|')
                    let symbol, name, category,industry, country, price
                    if (elArr.length === 5) {
                        symbol = elArr[0]
                        name = elArr[1]
                        category = elArr[2]
                        country = elArr[3]
                        price = elArr[4]
                    } else if (elArr.length > 5) {
                        symbol = elArr[0]
                        name = elArr[1]
                        category = elArr[2]
                        industry = elArr[3]
                        country = elArr[4]
                        price = elArr[5]
                    }

                    if (country) {
                        // Check if country entry already exists. If yes add to existing list of stocks
                        // Else, create new country entry
                        const countryIndex = newArr.findIndex(item => item.country === country)
                        if (countryIndex > -1) {
                            newArr[countryIndex].stocks.push({
                                symbol,
                                name,
                                category,
                                industry: industry?industry:null,
                                country,
                                price
                            })
                        } else {
                            // For some reason Communication Services gets filtered as a separate country
                            // With one stock in it. Removing via easy fix instead of finding the stock and investigating
                            if (country !== 'Communication Services') {
                                newArr.push({
                                    country,
                                    stocks: [{
                                        symbol,
                                        name,
                                        category,
                                        country,
                                        industry,
                                        price
                                    }]
                                })
                            }
                        }
                    }
                }
            });
        }

        // Store array in state
        return dispatch({type: 'set_data', payload: newArr})
    }

    return (
        <StorageContext.Provider value={{state, downloadStockList}}>
            {children}
        </StorageContext.Provider>
    )
}

export default StorageContext