// import { set } from 'date-fns'
import React, { useState, useEffect } from 'react'
import Button from '../components/Button'
import useInstrument from '../hooks/useInstrument'
import useErrorHandler from '../hooks/useErrorHandler'

export default function User() {
    const {getUserInfo, updateUserInfoCommand, changePasswordCommand} = useInstrument()
    const {errorHandler} = useErrorHandler()

    // local state
    const [user, setUser] = useState({
        name: '',
        email: '',
        stocks: [],
        portfolios: []
    })

    const [editing, setEditing] = useState(false)
    const [newUser, setNewUser] = useState('')
    const [newEmail, setNewEmail] = useState('')
    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')

    let nameField = null
    let emailField = null
    let editButton = null

    useEffect(() => {
        const loadUserData = async () => {
            try {
                const userData = await getUserInfo()
                setUser(userData)
                setNewUser(userData.name)
                setNewEmail(userData.email)
            } catch (err) {
                errorHandler(err)
            }
        }
        loadUserData()
    }, [])

    // handlers
    const updateUserInfo = async () => {
        try {
            const response = await updateUserInfoCommand(newUser, newEmail)
            let newState = {...user}
            newState.name = response.name
            newState.email = response.email
            setUser(newState)
            setEditing(false)
        } catch (err) {
            errorHandler(err)
        }
    }

    const changePasswordHandler = async (event) => {
        event.preventDefault()
        try {
            await changePasswordCommand(oldPass, newPass)
            setOldPass('')
            setNewPass('')
        } catch (e) {
            errorHandler(e)
        }
    }

    // pre-render

    const stockList = user.stocks.map(item => {
        return (
            <li key={`user_stock_${item}`}>{item}</li>
        )
    })
    
    const portfolioList = user.portfolios.map(item => {
        return (
            <li key={`user_port_${item.name}`}>{item.name} - {item.stocks.length} stocks</li>
        )
    })

    if (editing) {
        nameField = <p>Name: <input value={newUser} onChange={(event) => setNewUser(event.target.value)} placeholder={user.name} /></p>
        emailField = <p>Email: <input value={newEmail} onChange={(event) => setNewEmail(event.target.value)} placeholder={user.email} /></p>
        editButton = <div>
                <Button variant="square" onClick={updateUserInfo}>Save changes</Button>
                <Button variant="square" style="cancel" onClick={() => setEditing(false)}>Cancel</Button>
            </div>
    } else {
        nameField = <p>Name: {user.name}</p>
        emailField = <p>Email: {user.email}</p>
        editButton = <Button variant="square" onClick={() => setEditing(true)} >Edit info</Button>
    }



    return (
        <div className="user">
            <h2>User Screen</h2>
            <section>
                <h3>My Stocks</h3>
                <ul>
                    {stockList}
                </ul>
                <h3>My Portfolios</h3>
                <ul>
                    {portfolioList}
                </ul>
            </section>
            <section>
                <h3>User Info</h3>
                {nameField}
                {emailField}
                {editButton}
            </section>
            <section>
                <p>Change password here</p>
                <form onSubmit={changePasswordHandler}>
                    <p>Old Password:<input type="password" value={oldPass} onChange={(event) => setOldPass(event.target.value)} /></p>
                    <p>New Password:<input type="password" value={newPass} onChange={(event) => setNewPass(event.target.value)} /></p>
                    <Button variant="square" type="submit">Change</Button>
                </form>
            </section>
            <section>
                <p>For any issues with your subscription or problems with TS Mobile, please email us at:</p>
                <p><a href="mailto:support@timingsolutionmobile.com">support@timingsolutionmobile</a></p>
            </section>
        </div>
    )
}
