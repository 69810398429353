import {useContext} from 'react'
import dataServer from '../api/dataServer'
import authServer from '../api/authServer'
import DataContext from '../context/DataContext'
import UserContext from '../context/UserContext'
// import {Redirect} from 'react-router-dom'

// FOR TESTING IMPORT ONLY
import axios from 'axios'

export default () => {
    const {instrumentShown, updateStockChartData} = useContext(DataContext)
    const {portfolios, updateUserPortfolios} = useContext(UserContext)

    const getModelParams = async (modelIndex, stockType) => {
        const NodeRequest = {
            symbol: instrumentShown,
            chartkind: stockType,
            model_index: modelIndex
        }
        try {
            const response = await dataServer.post('/getModelData', NodeRequest)
            return response.data
        } catch (err) {
            return ({error: err.message})
        }
    }
    
    const qSpecDelete = async (stock, modelIndex, stockType) => {
        const NodeRequest = {
            stock,
            modelIndex,
            chartkind: stockType
        }
        try {
            await dataServer.post('/deleteModel', NodeRequest)
            return true
        } catch (err) {
            return {error: err.message}
        }
    }

    const qSpecAdd = async (stock, stockType) => {
        const NodeRequest = {
            stock: stock,
            chartkind: stockType,
            line_color: "#000",
            Kind: "4"
        }
        try {
            const response = await dataServer.post('/addqspectrum', NodeRequest)
            return response.data
        } catch (err) {
            return {error: err.message}
        }

    }

    const qSpecCalculatePeriodogram = async (object) => {
        try {
            const response = await dataServer.post('/calculatePeriodogram', object)
            return response.data
        } catch (err) {
            return {error: err.message}
        }
    }

    const qSpecCalculateProjLine = async (object) => {
        try {
            const response = await dataServer.post('/calculateSpectrumProjLine', object)
            return response.data
        } catch (err) {
            return {error: err.message}
        }
    }

    const getPeriodogram = async (modelIndex, stockType) => {
        const NodeRequest = {
            symbol: instrumentShown,
            chartkind: stockType,
            model_index: modelIndex
        }
        try {
            const response = await dataServer.post('/getPeriodogram', NodeRequest)
            return response.data
        } catch (err) {
            return ({error: err.message})
        }
    }

    const getPortfolioCommand = async (name) => {
        try {
            const response = await dataServer.post('/getPortfolio', {name})
            return response.data
        } catch (err) {
            throw new Error(err.response.status)
        }
    }

    const removeStockFromPortfolioCommand = async (portfolio, stock) => {
        let newPortfolios = [...portfolios]
        const index = newPortfolios.findIndex(item => item.name === portfolio)
        const stockIndex = newPortfolios[index].stocks.findIndex(item => item === stock)
        if (stockIndex > -1) {
            newPortfolios[index].stocks.splice(stockIndex, 1)
        }
        try {
            const response = await dataServer.patch('/editPortfolio', newPortfolios[index])
            updateUserPortfolios(newPortfolios)
            return response.data
        } catch (err) {
            throw new Error(err.response.status)
        }
    }

    const deletePortfolioCommand = async (portfolio) => {
        try {
            const response = await dataServer.delete('/deletePortfolio', {data:{name: portfolio}})
            updateUserPortfolios(response.data)
            return response.data
        } catch (err) {
            throw new Error (err.response.status)
        }
    }

    const addStockToPortfolio = async (portfolio, stock) => {
        let newPortfolios = [...portfolios]
        const index = newPortfolios.findIndex(item => item.name === portfolio)
        const stockIndex = newPortfolios[index].stocks.findIndex(item => item === stock)
        if (stockIndex > -1) {
            return ({error: 'Stock already included in portfolio'})
        }
        newPortfolios[index].stocks.push(stock)
        try {
            const response = await dataServer.patch('/editPortfolio', newPortfolios[index])
            updateUserPortfolios(newPortfolios)
            return response.data
        } catch (err) {
            newPortfolios[index].stocks.pop()
            throw new Error(err.response.status)
        }
    }

    const getUserInfo = async () => {
        try {
            const userInfo = await authServer.post('/me')
            return {name: userInfo.data.name, email: userInfo.data.email, stocks: userInfo.data.stocks, portfolios: userInfo.data.portfolios}
        } catch (err) {
            throw new Error (err.response.status)
        }
    }

    const updateUserInfoCommand = async (name, email) => {
        try {
            const NodeRequest = {
                name,
                email
            }
            const response = await authServer.patch('/me', NodeRequest)
            return response.data
        } catch (err) {
            throw new Error(err.response.status)
        }
    }

    const changePasswordCommand = async (oldPass, newPass) => {
        try {
            const NodeRequest = {
                oldPassword: oldPass,
                newPassword: newPass
            }
            const response = await authServer.post('/changePassword', NodeRequest)
            alert(response.data)
            return response.data
        } catch (err) {
            throw new Error(err.response.status)
        }
    }

    const removeSplitsCommand = async (stock) => {
        const request = {
            stock
        }
        const response = await dataServer.post('/splitRemove', request)
        return response.data
    }

    const updateInstrumentCommand = (instrument) => {
        updateStockChartData(instrument)
    }

    return {getModelParams, qSpecDelete, qSpecAdd, qSpecCalculatePeriodogram, qSpecCalculateProjLine, getPeriodogram, getPortfolioCommand, removeStockFromPortfolioCommand, deletePortfolioCommand, addStockToPortfolio, getUserInfo, updateUserInfoCommand, changePasswordCommand, removeSplitsCommand, updateInstrumentCommand}
}