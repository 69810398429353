export const updateInitStock = (state, stock) => {
    const stockIndex = state.findIndex(item => item.stock === stock.stock)
    state[stockIndex] = stock
    return state
}

export const selectColor = (i) => {
    const colors = ['#FF0000', '#047520', '#031bd2', '#7b00c8', '#d67204', '#008957', '#890033', '#2d4dd2', '#8ea320', '#e51a38', '#38e51a', '#1a38e5', '#8815EA', '#EA8815', '#15EA88','#FF0000', '#047520', '#031bd2', '#7b00c8', '#d67204', '#008957', '#890033', '#2d4dd2', '#8ea320', '#e51a38', '#38e51a', '#1a38e5', '#8815EA', '#EA8815', '#15EA88']
    return (colors[i])
}

export const addModelColors = (state) => {
    let newState = [...state]
    newState.forEach(stock => {
        let i = 0
        try {
            stock.data.forEach((element, index) => {
                if (index !== 0) {
                    element.color = selectColor(i)
                    i++
                    if (index !== 1) {
                        element.visible = true
                        element.visibleTemp = false
                    }
                }
            });
        } catch (err) {
        }
    });
    return newState
}

export const getBreakdown = (daily) => {
    let week = []
    for (let i = 0; i < 7; i++) {
        week.push(daily[i])

    }

    let weekReduced = []
    try {
        // get reduced arrays
        week.forEach(el => {
            if (el[2]>el[3]) {
                weekReduced.push([1, 0])
            } else if (el[2]<el[3]) {
                weekReduced.push([0, 1])
            } else {
                weekReduced.push([0, 0])
            }
        })
    } catch (e) {
        
    }

    return ({week: weekReduced})
}

export const addModelToStock = (state, stockIndex, model) => {
    // Double check  and remove double entries:
    state[stockIndex].data = state[stockIndex].data.filter(item => item.name !== model.name)
    // const double = state[stockIndex].data.find(item => item.name === model.name)
    // console.log(double)
    // if (double) {
    //     return state
    // }

    // ----------------------------------------

    let newState = [...state]
    model.visible = true
    model.visibleTemp = true
    model.yAxis = newState[stockIndex].data.length
    model.mIndex = model.yAxis - 1

    newState[stockIndex].data.push(model)


    // // assign axis numbers to ix charts
    // let axisNumber = 1
    // console.log(newState[stockIndex].data)
    // newState[stockIndex].data.forEach((entry, dataIndex) => {
    //     if (dataIndex !== 0) {
    //         if (entry.name[0] !== "S" && entry.name[0] !== "A") {
    //             console.log(axisNumber)
    //             entry.yAxis = axisNumber
    //             entry.mIndex = axisNumber - 1
    //             axisNumber++
    //         }
    //     }
    //     if (dataIndex > 0 && (entry.name[0] !== "S" || entry.name[0] !== "A")) {
    //         console.log(axisNumber)
    //         entry.yAxis = axisNumber
    //         entry.mIndex = axisNumber - 1
    //         axisNumber++
    //     }
    // })
    return newState
}

export const removeModel = (state, stockIndex, realModelIndex, name) => {
    let newState = [...state]
    newState[stockIndex].data = newState[stockIndex].data.filter(el => el.name !== name)
    
    // newState[stockIndex].data.splice(realModelIndex, 1)
    newState[stockIndex].data.forEach((el, index) => {
        if (index !== 0) {
            el.yAxis = index
            el.mIndex = index - 1
        }
    })
    const output = addModelColors(newState)
    return output
}

export const editModel = (state, stockIndex, modelIndex, model) => {
    let newState = [...state]
    newState[stockIndex].data[modelIndex+1] = model
    return newState
}

export const removeStock = (state, stock) => {
    let newState = [...state]
    const index = newState.findIndex(el => el.stock === stock)
    newState.splice(index, 1)
    return newState
}

export const removeUserStock = (state, stock) => {
    let newState = {...state}
    let index = newState.stocks.findIndex(el => el === stock)
    if (index === -1) {
        index = newState.customStocks.findIndex(el => el === stock)
        newState.customStocks.splice(index, 1)
        return newState
    }
    newState.stocks.splice(index, 1)
    return newState
}

export const addUserStock = (state, stock) => {
    if (!state.stocks.includes(stock)) {
        let newState = {...state}
        newState.stocks.push(stock)
        return newState
    } else {
        return state
    }
}

export const addUserStockCustom = (state, stock) => {
    let newState = {...state}
    newState.customStocks.push(stock)
    return newState
}

export const updatePortfolios = (state, portfolios) => {
    let newState = {...state}
    newState.portfolios = portfolios
    return newState
}

export const updateLBC = (state, payload) => {
    let newState = [...state]
    payload.models.forEach((model, relIndex) => {
        // find index of model in data
        const modelIndex = newState[payload.index].data.findIndex(item => item.name === model.name)
        newState[payload.index].data[modelIndex].data = payload.models[relIndex].data
    })
    newState[payload.index].LBCBars = payload.LBCBars
    newState[payload.index].LBCTime = payload.LBCTime
    return newState
}

export const addIxData = (state, payload) => {
    let newState = [...state]
    newState[payload.index].data[payload.modelIndex].data = payload.data
    // assign axis numbers to ix charts
    let axisNumber = 2
    newState[payload.index].data.forEach((entry, dataIndex) => {
        if (dataIndex !== 0) {
            if (entry.name[0] !== "S" && entry.name[0] !== "A") {
                entry.yAxis = axisNumber
                axisNumber++
            }
        }
        // if (dataIndex > 0 && entry.name[0] !== "S" || entry.name[0] !== "A") {
        //     entry.yAxis = axisNumber
        //     axisNumber++
        // }
    })
    return newState
}

export const confirmDownloadComplete = (stockLength, loadedCount) => {
    if (stockLength === loadedCount) {
        return true
    } else return false
}