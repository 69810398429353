import React, {useContext, useState} from 'react'
import Highcharts from 'highcharts/highcharts';
import HighchartsReact from 'highcharts-react-official';
import Spinner from '../components/Spinner';
import Button from '../components/Button';
import NumericInput from 'react-numeric-input';
import useInstrument from '../hooks/useInstrument';
import UserContext from '../context/UserContext'


export default function AstroDialog({modelIndex, type, deleteVisible, stockName, stockType, closeClicked, submitClicked, deleteClicked, loading}) {
  const {email} = useContext(UserContext)
    const {qSpecDelete, qSpecAdd, qSpecCalculatePeriodogram, getModelParams, getPeriodogram} = useInstrument()


    // state definition
    const [qSpectrumModelOptions, setQSpectrumModelOptions] = React.useState({
        qSpec_model_sm: '12',
        qSpec_model_overtones: '4',
        qSpec_model_sz: 50,
        qSpec_model_fsm: '12',
        qSpec_model_kind: '4',
        loading: false,
        submitDisabled: false,
        model_index: modelIndex,
        qSpec_color: "#000000",
        chosen_cycles: null,
        customCycles: '',
        options: {
          title: {
            text: ''
          },
          chart: {
            height: '200px'
          },
          xAxis: {
            type: 'logarithmic'
          },
          yAxis: [{
            title: false,
            labels: {
              enabled: false
            }
          }],
          series: [{
            type: 'line',
            data: []
          }],
          legend: {
            enabled: false
          },
          responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                chart: {
                  height: '200px'
                },
                line: {
                  states: {
                    hover: {
                      lineWidthPlus: 0
                    },
                  }
                },
                states: {
                  hover: {
                    enabled: false
                  }
                },
                plotLines: {
                  label: {
                      text: '',
                      y: 0
                    }
                  }
              }
            }]
          }
        }
      })
    

      // initial
      React.useEffect(() => {
        // function def
        const addQSpectrumModel = async () => {
              const data = await qSpecAdd(stockName, stockType)
              let newState = {...qSpectrumModelOptions}
              newState.model_index = data.model_index
              newState.options.series[0].data = data.data
              newState.chosen_cycles = data.chosen_cycles
              newState.chosen_cycles.forEach(element => {
                if (element[1]==='true') {
                  element[1] = true
                } else {
                  element[1] = false
                }
              })
              newState.options.xAxis.plotLines = data.chosen_cycles.map(element => ({
                value: element[0],
                width: 0.5,
                label: {
                  text: element[0],
                  align: 'right',
                  y: 150
                }
              }))
              newState.loading = false
              newState.submitDisabled = false
              setQSpectrumModelOptions(newState)
        }
        const getQSpecData = async () => {
          const modelParams = await getModelParams(modelIndex, stockType)        
          const periodogram = await getPeriodogram(modelIndex, stockType)
          let newState = {...qSpectrumModelOptions}
          newState.qSpec_model_sm = modelParams.SM
          newState.qSpec_model_fsm = modelParams.FSM
          newState.qSpec_model_sz = modelParams.SZ
          newState.qSpec_model_kind = modelParams.Kind
          newState.qSpec_model_overtones = modelParams.Overtones
          newState.options.series[0].data = periodogram.data
          newState.chosen_cycles = periodogram.chosen_cycles
          newState.customCycles = periodogram.customCycles
          newState.chosen_cycles.forEach(element => {
            if (element[1]==='true') {
              element[1] = true
            } else {
              element[1] = false
            }
          })
          newState.options.xAxis.plotLines = periodogram.chosen_cycles.map(element => ({
            value: element[0],
            width: 0.5,
            label: {
              text: element[0],
              align: 'right',
              y: 150
            }
          }))
          newState.loading = false
          setQSpectrumModelOptions(newState)
        }

        // ops
        if (type==="New") {
          let newState = {...qSpectrumModelOptions}
          newState.loading = true
          setQSpectrumModelOptions(newState)
          addQSpectrumModel()
        } else if (type==="Edit") {
          let newState = {...qSpectrumModelOptions}
          newState.loading = true
          setQSpectrumModelOptions(newState)
          getQSpecData()
      } else {
      }
      
      // return () => {
      //   // cleanup
      // }
    }, [])

    // *********** OPERATIONS **************
    const qSpecSMChange = (value) => {
      const newState={...qSpectrumModelOptions}
      newState.qSpec_model_sm = value
      newState.submitDisabled = true
      setQSpectrumModelOptions(newState)
    }
  
    const qSpecSZChange = (value) => {
      const newState={...qSpectrumModelOptions}
      newState.qSpec_model_sz = parseInt(value)
      newState.submitDisabled = true
      setQSpectrumModelOptions(newState)
    }
  
    const qSpecOvertonesChange = (value) => {
      let newState={...qSpectrumModelOptions}
      newState.qSpec_model_overtones = value
      setQSpectrumModelOptions(newState)
    }

    const qSpecFSMChange = (value) => {
        const newState={...qSpectrumModelOptions}
        newState.qSpec_model_fsm = value
        setQSpectrumModelOptions(newState)
    }

    const qSpecKindChange = (event) => {
      const newState={...qSpectrumModelOptions}
      newState.qSpec_model_kind = event.target.value
      newState.submitDisabled = true
      setQSpectrumModelOptions(newState)
    }

    const cancelModelHandler = async () => {
      if (type==="New") {
        await qSpecDelete(stockName, qSpectrumModelOptions.model_index, stockType)
        closeClicked()
      } else if (type==="Edit") {
        closeClicked()
      }
    }

    const submitClickedHandler = () => {
      let chosen_cyclesString = ''
      qSpectrumModelOptions.chosen_cycles.forEach((element, index) => {
        chosen_cyclesString = chosen_cyclesString + element[1]
        if (qSpectrumModelOptions.chosen_cycles[index+1]) {
          chosen_cyclesString = chosen_cyclesString + "@"
        }
      });
      const submitObject = {
        symbol: stockName,
        chartkind: stockType?stockType:'standard',
        model_index: qSpectrumModelOptions.model_index,
        Kind: qSpectrumModelOptions.qSpec_model_kind,
        SM: qSpectrumModelOptions.qSpec_model_sm,
        FSM: qSpectrumModelOptions.qSpec_model_fsm,
        SZ: qSpectrumModelOptions.qSpec_model_sz,
        Overtones: qSpectrumModelOptions.qSpec_model_overtones,
        color: '#000',
        Target: '50',
        customCycles: qSpectrumModelOptions.customCycles,
        chosen_cycles: chosen_cyclesString,
        type: 'qspec'
      }
      submitClicked(submitObject)
    }

    const generatePeriodogramHandler = async () => {
        // Set Loading
        let newState = {...qSpectrumModelOptions}
        newState.loading = true
        setQSpectrumModelOptions(newState)

        const SecondRequest = {
        stock: stockName,
        chartkind: stockType?stockType:'standard',
        model_index: qSpectrumModelOptions.model_index,
        Kind: qSpectrumModelOptions.qSpec_model_kind,
        SM: qSpectrumModelOptions.qSpec_model_sm,
        SZ: qSpectrumModelOptions.qSpec_model_sz,
        Overtones: qSpectrumModelOptions.qSpec_model_overtones,
        FSM: qSpectrumModelOptions.qSpec_model_fsm
        }

        const data = await qSpecCalculatePeriodogram(SecondRequest)
        if (!data.error) {
          newState = {...qSpectrumModelOptions}
          newState.options.series[0].data = data.data
          newState.chosen_cycles = data.chosen_cycles
          newState.chosen_cycles.forEach(element => {
              if (element[1]==='true') {
              element[1] = true
              } else {
              element[1] = false
              }
          });
          newState.loading = false
          newState.submitDisabled = false
          newState.options.xAxis.plotLines = data.chosen_cycles.map(element => ({
              value: element[0],
              width: 1,
              label: {
              text: element[0],
              align: 'right'
              }
          }))
          setQSpectrumModelOptions(newState)
        } else {
          alert(data.error)
          newState = {...qSpectrumModelOptions}
          newState.loading = false
          setQSpectrumModelOptions(newState)
        }
    }

    const cycleCheckboxChangeHandler = (index) => {
      let newState = {...qSpectrumModelOptions}
      newState.chosen_cycles[index][1] = !newState.chosen_cycles[index][1]
      setQSpectrumModelOptions(newState)
    }

    const customCyclesChangeHandler = (event) => {
      const newState = {...qSpectrumModelOptions}
      newState.customCycles = event.target.value
      setQSpectrumModelOptions(newState)
    }

    // Pre-Render Code
    // Delete Button Visibility
    let deleteButton = null
    if (deleteVisible) {
        deleteButton = <Button className="button__model--cancel" onClick={deleteClicked}>Remove</Button>
    }

    let QSpecDialog = null

    if (qSpectrumModelOptions.loading) {
      QSpecDialog = <div className="qspec__loading">
          <Spinner />
          <h3>Loading Data, please wait...</h3>
        </div>
    } else {
      QSpecDialog =<HighchartsReact options={qSpectrumModelOptions.options} highcharts={Highcharts} />
    }

    let QSpecCycles = <Spinner />
    if (qSpectrumModelOptions.chosen_cycles) {
      QSpecCycles = qSpectrumModelOptions.chosen_cycles.map((item, index) => (
        <div key={"cycle"+index}>
          <input type="checkbox" id={"cycle" + index} name={"cycle" + index} value={item[0]} checked={qSpectrumModelOptions.chosen_cycles[index][1]} onChange={() => cycleCheckboxChangeHandler(index)} />
          <label htmlFor={"cycle"+index}>{item[0]}</label><br />
        </div>
      ))
    }

    // 
    let title
    if (type==="New") {
      title = "Add Spectrum Module"
    } else {
      title = "Edit Spectrum Module"
    }

    // Submit button disabled
    let submitButtonDisabled = false
    if (loading || qSpectrumModelOptions.submitDisabled) {
      submitButtonDisabled = true
    }

    return (
        <React.Fragment>
        <div id="form-dialog-title" className="qspec__title">{title}</div>
        <div className="qspec__content">
          <div className="qspec__content--periodogram">
            {QSpecDialog}
          </div>
          <div className="qspec__content--options">
            <span>Spectrum Settings:</span>
            <div className="qspec__content--important">
              <span>Kind:</span>
              <select value={qSpectrumModelOptions.qSpec_model_kind} onChange={qSpecKindChange} className="qspec__content--kind">
                  <option value='0'>Classic</option>
                  <option value='1'>Multi</option>
                  <option value='2'>Q-Spectrum</option>
                  <option value='3'>Bartels</option>
                  <option value='4'>Bartels-Tarasov</option>
              </select>
              <span>Stock Memory:</span>
              <NumericInput value={qSpectrumModelOptions.qSpec_model_sm} onChange={qSpecSMChange} mobile size={5} />
              <span>Sample Size:</span>
              <NumericInput value={qSpectrumModelOptions.qSpec_model_sz} onChange={qSpecSZChange} mobile size={5} />
              <br />
              <Button className="button__qspec--calculate" onClick={generatePeriodogramHandler}>Generate Periodogram</Button>
            </div>
            <div className="qspec__content--extra">
              <span>Overtones:</span>
              <NumericInput min={1} value={qSpectrumModelOptions.qSpec_model_overtones} onChange={qSpecOvertonesChange} mobile size={5} />
              <br/>
              <span>FSM:</span>
              <NumericInput min={1} max={1000} value={qSpectrumModelOptions.qSpec_model_fsm} onChange={qSpecFSMChange} mobile size={5} />
              {/* <span>Custom Cycles: </span>
              <input type="text" value={qSpectrumModelOptions.customCycles} onChange={customCyclesChangeHandler}></input> */}
            </div>
          </div>
          <div className="qspec__content--cycles">
            Cycles:
            {QSpecCycles}
          </div>
        </div>
        <div className="dialog__actions--right">
          {deleteButton}
          <Button className="button__model--cancel" onClick={cancelModelHandler}>
            Cancel
          </Button>
          <Button 
              disabled={submitButtonDisabled} 
              className="button__model--submit" 
              onClick={submitClickedHandler}>
              {loading ? 'Calculating' : 'Submit'}
          </Button>
        </div>
        </React.Fragment>
    )
}