import React from 'react';
// import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client'
import './sass/main.scss';

import App from './App';

import {UserProvider} from './context/UserContext';
import {DataProvider} from './context/DataContext';
import {StorageProvider} from './context/StorageContext';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <UserProvider>
      <StorageProvider>
        <DataProvider>
          <App />
        </DataProvider>
      </StorageProvider>
    </UserProvider>
  </React.StrictMode>
)

// ReactDOM.render(
//   <React.StrictMode>
//       <UserProvider>
//         <DataProvider>
//           <App />
//         </DataProvider>
//       </UserProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

