import React, { useEffect, useContext, useState } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import Dialog from '../components/Dialog'
import Button from '../components/Button'
import AstroDialog from '../dialogs/AstroDialog'
import QSpecDialog from '../dialogs/QSpectrum'
import IxDialog from '../dialogs/IxDialog'
import moment from 'moment'
import DataContext from '../context/DataContext'
import UserContext from '../context/UserContext'
import {getBreakdown} from '../utils/processor'
import useContextCommunicator from '../hooks/useContextCommunicator'
import useErrorHandler from '../hooks/useErrorHandler'
import useInstrument from '../hooks/useInstrument'
import { AiOutlineArrowUp, AiOutlineArrowLeft, AiOutlineArrowDown } from 'react-icons/ai'
import { NavLink, Navigate } from 'react-router-dom'
// import { parseISO, set } from 'date-fns'

export default function Instrument() {
    // ----------------- context defs
    const { instrumentShown, addModel, deleteModel, editModel, visitedFrom, setLBCCommand, loadedCount, addedFromSearch, setAddedFromSearchFunc } = useContext(DataContext)
    const { stocks, customStocks } = useContext(UserContext)
    const {deleteInstrument, getInstrument, addInstrumentCommand} = useContextCommunicator()
    const {errorHandler} = useErrorHandler()
    const {removeSplitsCommand, updateInstrumentCommand, qSpecCalculateProjLine} = useInstrument()
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    // ----------------- state and var defs
    const [instrument, setInstrument] = useState({
        code: 'None',
        title: 'None',
        data: [],
        zones: [],
        loaded: false,
        stockIsFavorite: false,
        LBCBars: 0,
        LBCTime: new Date()
    })
    const [addDialog, setAddDialog] = useState(false)
    const [astroOpen, setAstroOpen] = useState(false)
    const [qspecOpen, setQspecOpen] = useState(false)
    const [ixOpen, toggleIx] = useState(false)
    const [openZones, setOpenZones] = React.useState(0)
    const [editDialog, setEditDialog] = useState({
        editOpen: false,
        editIndex: null,
        type: null,
        name: ''
    })
    const [lbcDisabled, setLBCDisabled] = useState(true)
    const [NavigateElement, setNavigate] = useState(null)
    const [LBCValue, setLBCValue] = useState(0)
    const [expertsShow, setExpertsShow] = useState(false)
    const [calculating, setCalculating] = useState(false)
    const [showVolume, setShowVolume] = useState(false)

    // --------------- data load
    const loadFunc =  async () => {
        let stockFavorite = false
        // Check if instrument is in user favorites
        let stockIndex = stocks.findIndex(item => item === instrumentShown)
        let customIndex = customStocks.findIndex(item => item === instrumentShown)
        if (stockIndex !== -1 || instrumentShown === '' || customIndex !== -1) {
            stockFavorite = true
        } else {
            stockIndex = customStocks.findIndex(item => item === instrumentShown)
            if (stockIndex !== -1) stockFavorite = true
        }
        // if (dataStatus === "download_complete") {
            try {
                let data = await getInstrument()
                if (data && data.loaded) {
                    if (data.stock !== "None") {
                        const headerArr = data.header.split(' - ')
                        data.code = headerArr[0]
                        data.title = headerArr[1]
                        data.stockIsFavorite = stockFavorite

                        // see if minMax values are set, if not then generate them
                        if (!data.minTime) {
                            data.minTime = (moment(new Date()).valueOf()) - (1000*3600*24*365*1.5)
                        }

                        if (!data.maxTime) {
                            data.maxTime = (moment(new Date()).valueOf()) + (1000*3600*24*180)
                        }
                        
                        setInstrument(data)
                        setLBCValue(data.LBCBars)
                    } else {
                        setNavigate(<Navigate to="/scanner" />)
                    }
                } else {
                }
            } catch (e) {
                console.log(e.message)
                errorHandler(e)
            }
        // }
    }

    useEffect(() => {
        // Get Data
        if ((instrument.stock !== instrumentShown) || (!instrument.loaded && (loadedCount === (stocks.length + customStocks.length)))) {
            loadFunc()
        }

        // look for empty models
        instrument.data.forEach(entry => {
            if (!entry.data.length) {
                // updateEmptyIxData(instrument.code, entry.name)
            }
        })
        // separate volume from stock data, add as a second entry in the array
        // if (instrument.data.findIndex(el => el.name === 'Volume') > -1 && instrument.data[1].visible !== showVolume) {
        //     const newData = {...instrument}
        //     if (showVolume) {
        //         newData.data[1].visible = true
        //         newData.data[1].visibleTemp = true
        //     } else {
        //         newData.data[1].visible = false
        //         newData.data[1].visibleTemp = false
        //     }
        //     setInstrument(newData)
        //     setShowVolume(true)
        // }

        return () => {
        }

    }, [loadedCount, instrumentShown, instrument, addModel, deleteModel, showVolume])

    // yAxis setup based on how many models exist
    let yAxisParams = [{}]
    instrument.data.forEach((element, index) => {
        if (index !== 0) {
            let modelAxisObject = {
                height: '100%',
                visible: false
            }
            yAxisParams.push(modelAxisObject)
        }
    })
    

    // // Graph data setup
    // const minTime = (moment(new Date()).valueOf()) - (1000*3600*24*180)
    // const maxTime = (moment(new Date()).valueOf()) + (1000*3600*24*180)

    const chartOptions = {
        tooltip: {

            formatter: function() {
              if (this.points[0].series.name==='Series 1') {
                let date = new Date(this.x)
                const day = date.getUTCDate()
                const month = date.getUTCMonth()+1
                const year = date.getUTCFullYear()

                let zone_description = 'Closing Price: '+this.y+`<br>Date: ${day}/${month}/${year}`

                return zone_description;
              } else {
                // return 'Date: '+moment(this.x).format('X')
                let date = new Date(this.x)
                const day = date.getUTCDate()
                const month = date.getUTCMonth()+1
                const year = date.getUTCFullYear()
                return `Date: ${day}/${month}/${year}`
              }
            }
        },
        // rangeSelector: {
        //     // zoom buttons for 1m 3m YTD etc. disabled, change later if needed
        //     buttonTheme: {
        //         visibility: 'hidden'
        //     },
        // },
        boost: {
            enabled: true,
            useGPUTranslations: true,
        },
        plotOptions: {
          series: [{
            dataGrouping: {
                units: [[
                    'day', // unit name
                    [1] // allowed multiples
                ], [
                    'month',
                    [1, 2, 3, 4, 6]
                ]]
            },
            // general options for all series
            turboThreshold: 1000,
            // compare: 'percent',
            showInNavigator: true,
            lineWidth: 2,
            states: {
                hover: {
                    lineWidth: 3
                }
            }
          }, {showInNavigator: false}]
        },
        // rangeSelector: {
        //     selected: 0
        // },
        xAxis: {
            min: instrument.minTime,
            max: instrument.maxTime,
            // plotBands: predictableZonesChart,
            plotLines: [{
                color: '#FF0000',
                width: 2,
                value: instrument.LBCTime,
                label: {
                  text: "LBC: "+moment(parseInt(instrument.LBCTime)).format('L')
                  },
                zIndex: 10
                // label: {
                //   formatter:function(){
                //       return Highcharts.dateFormat('%Y %M %d',this.value);
                //   }
                // }
              }],
        },
        yAxis: yAxisParams,
        series: instrument.data
        // }, projLine]
    }

    // --------------- handlers

    const openAstroDialog = () => {
        setAstroOpen(true)
        setAddDialog(false)
    }

    const openQSpecDialog = () => {
        setQspecOpen(true)
        setAddDialog(false)
    }

    const submitModelHandler = async (model) => {
        setCalculating(true)
        try {
            await addModel(model)
            let newState = {...instrument}
            setInstrument(newState)
    
            if (model.type === 'astro') {
                setAstroOpen(false)
            } else if (model.type === 'qspec') {
                setQspecOpen(false)
            }
        } catch (e) {
            errorHandler(e.message)
        }
        setCalculating(false)
        loadFunc()
    }

    const openEditDialog = (modelIndex) => {
        let modelIndexToSubmit = modelIndex
        // if (instrument.data.findIndex(el => el.name === 'Volume') > -1) {
        //     modelIndexToSubmit = modelIndex-1
        // }
        const modelInfo = {...instrument.data[modelIndexToSubmit+1]}
        let newState = {...editDialog}
        newState.editIndex = modelIndex
        newState.name = modelInfo.name

        if (modelInfo.name[0] === 'A') {
            newState.type = 'astro'
            newState.editOpen = true
        } else if (modelInfo.name[0] === 'S') {
            newState.type = 'qSpec'
            newState.editOpen = true
        } else if (modelInfo.name[0] === 'I') {
            toggleIx(true)
        }
        setEditDialog(newState)
    }

    const deleteModelHandler = async (modelIndex) => {
        // console.log(editDialog)
        try {
            await deleteModel(modelIndex, editDialog.name)

            loadFunc()
            closeEditDialog()
        } catch (e) {
            errorHandler(e)
        }
    }

    const closeEditDialog = () => {
        let newState = {...editDialog}
        newState = { 
            editOpen: false,
            editIndex: null,
            type: null,
            name: ''
        }
        setEditDialog(newState)
    }

    const submitEditHandler = async (model, modelIndex) => {
        try {
            const editedModel = await editModel(modelIndex, model)
            let newState = {...instrument}
            newState.data[modelIndex+1] = editedModel
            setInstrument(newState)
            closeEditDialog()
        } catch (e) {
            errorHandler(e)
        }
    }

    const toggleModelVisibility = (index) => {
        let newState = {...instrument}

        // Temporary workaround code: visbility is now controlled by lineWidth. based on visibleTemp parameter, lineWidth is set to either 1 or 0
        // newState.data[index+1].visibleTemp = !newState.data[index+1].visibleTemp
        // if (newState.data[index+1].visibleTemp) {
        //     newState.data[index+1].lineWidth = 1
        // } else {
        //     newState.data[index+1].lineWidth = 0
        // }

        // old code, revive when HighCharts update comes out
        newState.data[index+1].visible = !newState.data[index+1].visible
        setInstrument(newState)
    }

    const openZoneDetailHandler = (index) => {
        if (openZones === 0) {
            setOpenZones(index)
        } else {
            setOpenZones(0)
        }
    }

    const addInstrumentHandler = async () => {
        try {
            let updatedInstrument = await addInstrumentCommand(instrumentShown)
            updatedInstrument.stockIsFavorite = true
            setAddedFromSearchFunc(true)
            setInstrument(updatedInstrument)
        } catch (e) {
            errorHandler(e)
        }
    }

    const openIntermarketDialog = () => {
        toggleIx(true)
    }

    const LBCBarsChangedHandler = (event) => {
        setLBCDisabled(false)
        setLBCValue(event.target.value)
    }

    const LBCRecalculateHandler = async () => {

        // if (instrument.LBCBars < 0) {
        //     return alert('LBC Cannot be less than 0')
        // }

        const newData = await setLBCCommand(instrument.stock, Math.abs(LBCValue), instrument.type)
        let newState = {...instrument}

        newData.models.forEach((model, relIndex) => {
            // find index of model in data
            const modelIndex = newState.data.findIndex(item => item.name === model.name)
            newState.data[modelIndex].data = newData.models[relIndex].data
        })
        newState.LBCBars = newData.LBCBars
        newState.LBCTime = newData.LBCTime
        setInstrument(newState)
        setLBCDisabled(true)
        setLBCValue(Math.abs(LBCValue))
    }

    const expertsShowHandler = () => {
        setExpertsShow(!expertsShow)
    }

    const expertsIxHandler = () => {
        setExpertsShow(!expertsShow)
        toggleIx(true)
    }

    const toggleShowingSplitsHandler = async () => {
        const newChartData = await removeSplitsCommand(instrument.stock)
        let newState = {...instrument}
        newState.data[0].data = newChartData
        updateInstrumentCommand(newState)
    }


    // --------------- pre-render
    // todays date
    const today = moment().add(6, 'days').format('MMM-DD')

    // daily breakdowns
    let breakdown = {week: []}
    if (instrument.loaded) {
        breakdown = getBreakdown(instrument.daily)
    }

    const weekDays = breakdown.week.map(element => {
        const key = "week" + Math.floor(Math.random()*10000)
        if (element[0]>element[1]) {
            return (<div key={key} className="segment segment--green"></div>)
        } else if (element[0]<element[1]) {
            return (<div key={key} className="segment segment--red"></div>)
        } else {
            return (<div key={key} className="segment segment--blue"></div>)
        }
    })

    // models & top header

    let modelsDiv = <div className="instrument--models instrument--models__topBar">
        {/* <Button variant="instrument_basic" onClick={toggleShowingSplitsHandler}>Remove splits</Button> */}
        <p style={{fontStyle: 'italic'}}>No models</p>
        <p>ADD TO FAVORITES TO ENABLE ANALYSIS</p>
    </div>
    let models = null
    if (instrument.stockIsFavorite && instrument.data.length > 0) {
        // models
        const modelsArray = instrument.data.filter((element, index) => index !== 0)
        let volumeExists = false
        models = modelsArray.map((element, index) => {
            if (element.name === 'Volume') {
                volumeExists = true
                return
            }
            let modelIndex = volumeExists?index-1:index
            let key = "model"+modelIndex

            let headerText, modelText
            if (element.name[0] === 'A') {
                headerText = 'Natural'
                modelText = element.header
            } else if (element.name[0] === 'S') {
                headerText = 'Spectrum'
                modelText = element.header
            } else if (element.name[0] === 'I') {
                headerText = 'Intermarket'
                modelText = element.header
            }


            return (
                <div key={key} className="instrument__modelDiv">
                    <input className="instrument__modelDiv--input"
                        type="checkbox"
                        style={{width: '1.2rem', height: '1.2rem'}}

                        // workaround code until Highcharts update
                        // checked={modelsArray[index].visibleTemp}

                        // old line below. restore when Highcharts is updated
                        checked={modelsArray[index].visible}
                        onChange={() => toggleModelVisibility(modelIndex)}
                    />
                    <div className="instrument__modelDiv--chunk" onClick={() => openEditDialog(modelIndex)}>
                        <div 
                        className="instrument__modelDiv--color" 
                        style={{backgroundColor: element.color, height: '1.2rem', width: '1.2rem'}}>
                        </div>
                        <div style={{flexDirection: 'row', flex: 1}}>
                            <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                {headerText}
                            </div>
                            {modelText ? 
                                <div style={{textAlign: 'center'}}>
                                    {modelText}
                                </div>
                            :
                            null
                            }
                        </div>
                        {/* <span>
                            {header}
                        </span> */}
                    </div>
                </div>
            )
        })

        if (!models.length) {
            models = <div className="instrument--models__topBar">
            <p style={{fontStyle: 'italic'}}>No models</p>
            {/* <Button variant="instrument_basic" onClick={() => setAddDialog(true)}>Add Model</Button> */}
        </div>
        }

        modelsDiv = <div className="instrument--models">
            <div style={{'margin': '0 auto', 'textAlign':'center'}}>
            <Button variant="instrument_basic" onClick={() => setAddDialog(true)}>Add Model</Button>
            </div>

            <div className="instrument--models__list">
                {models}
            </div>
        </div>

    }

    // tradable zones
    let zonesElement = null
    if (instrument.zones) {
        zonesElement = instrument.zones.map((element, index) => {
            let title = 'Cycle'+index
            const dateFrom = moment('01/01/2021', 'MM/DD/YYYY').add(element[1], 'days').format('DD-MMM')
            const dateTo = moment('01/01/2021', 'MM/DD/YYYY').add(element[2], 'days').format('DD-MMM')
            
            let trend = "No"
            let trendIcon
            if (element[0][0] > element[0][1]) {
                trend = "Up"
                trendIcon = <AiOutlineArrowUp color="green" />
            } else if (element[0][0] < element[0][1]) {
                trend = "Down"
                trendIcon = <AiOutlineArrowDown color="red" />
            }
            let averageMovement = []
            const zoneBreakdown = element[0][3].map((zoneYear) => {
                let changeVal = (zoneYear.close - zoneYear.open).toFixed(2)
                let openVal = zoneYear.open.toFixed(2)
                let closeVal = zoneYear.close.toFixed(2)
                if (changeVal === '0.00') {
                    changeVal = (zoneYear.close - zoneYear.open).toFixed(4)
                    openVal = zoneYear.open.toFixed(4)
                    closeVal = zoneYear.close.toFixed(4)
                }
                const changePercent = (changeVal/zoneYear.open)*100
                let zoneColorClass = "instrument--tzs__zone-green"
                if (changeVal <= 0) {
                    zoneColorClass = "instrument--tzs__zone-red"
                }
                averageMovement.push(changePercent)
                return <li className={zoneColorClass} key={title+zoneYear.year}>{zoneYear.year}/ {zoneYear.from[0]} - {zoneYear.to[0]}  / Open: {openVal} / Close: {closeVal} / Change: ${changeVal} / <b>% {changePercent.toFixed(2)}</b></li>
            })
    
            let detailClass = "instrument--tzs__zone--hidden"
            if (openZones === index+1) {
                detailClass = "instrument--tzs__zone--shown"
            }

            // Calculate Average Change
            let averageSum = 0
            averageMovement.forEach(item => {
                averageSum = averageSum + item
            })
            const average = (averageSum/averageMovement.length).toFixed(2)

            // Calculate Median Value
            // console.log(averageMovement.sort())
            let medianValue = ''
            const sortedArr = averageMovement.sort(function(a, b) {
                return a - b;
            })
            if (averageMovement.length%2 == 0) {
                const firstIndex = (sortedArr.length/2)-1
                const secondIndex = firstIndex+1
                medianValue = ((sortedArr[firstIndex]+sortedArr[secondIndex])/2).toFixed(2)
            } else {
                const midpoint = parseInt(sortedArr.length/2)
                medianValue = (sortedArr[midpoint]).toFixed(2)
            }

            return (
                <div key={title} className="instrument--tzs__zone" onClick={() => openZoneDetailHandler(index+1)}>
                    <p>{trendIcon} <span style={{fontWeight: 'bold'}}>{trend}</span> trend from: {dateFrom} to: {dateTo}. <span style={{fontWeight: 'bold'}}>Average</span>: {average}%. <span style={{fontWeight: 'bold'}}>Median</span>: {medianValue}%</p>
                    <ul className={detailClass}>
                        {zoneBreakdown}
                    </ul>
                </div>
            )
        })
    }

    // edit dialog
    let dialogContent = null
    if (editDialog.type === 'astro') {
        dialogContent = <AstroDialog 
        type="Edit"
        deleteVisible={true}
        stockName={instrumentShown}
        modelIndex={editDialog.editIndex}
        deleteClicked={() => deleteModelHandler(editDialog.editIndex)}
        closeClicked={closeEditDialog}
        submitClicked={(object) => submitEditHandler(object, editDialog.editIndex)} />
    } else if (editDialog.type === 'qSpec') {
        dialogContent = <QSpecDialog
        type='Edit'
        stockName={instrumentShown}
        stockType={instrument.type}
        modelIndex={editDialog.editIndex}
        deleteVisible={true}
        closeClicked={closeEditDialog}
        deleteClicked={() => deleteModelHandler(editDialog.editIndex)}
        submitClicked={(object) => submitEditHandler(object, editDialog.editIndex)} />
    }

    // intermarket string
    let ixDiv = null
    // let ixDialogDiv = null
    if (instrument.ix && instrument.ix.length) {
        ixDiv = (
            <div className="ix__line" onClick={openIntermarketDialog}>
                <p>Intermarket Analysis: <b>{instrument.ix.length}</b> Leading Instruments found. <b>Click to View</b></p>
            </div>)

            
        // ixDialogDiv = instrument.ix.map(item => {
        //     return (
        //         <li className="ix__item" onClick={() => getIntermarketChartHandler(item[0],item[2], item[4])}>{item[0]} - {item[1]}: {item[2]}, {item[3]}, {item[4]}</li>
        //     )
        // })
    }


    // Header
    let header
    let expertsClass = "instrument__experts-dialog instrument__experts-dialog--hidden"
    if (expertsShow) {
        expertsClass = "instrument__experts-dialog"
    }
    if (!instrument.loaded) {
        header = <div className="instrument__preview">
            <h4>Loading...</h4>
            </div>
    } else if (instrument.stockIsFavorite) {
        header = <div className="instrument--title">
            <div className="instrument--title__row">
                <div>
                    <div style={{display: 'flex'}}>
                        {addedFromSearch ? <NavLink to={visitedFrom} style={{marginRight: '10px'}} className="instrument--title__back"><AiOutlineArrowLeft size="36" color="black" /><span>Back to Results</span></NavLink> : null }
                        {/* <Button variant="instrument_basic" className="instrument_anchor" onClick={expertsShowHandler}>TS Experts</Button> */}
                    </div>
                    <div className={expertsClass}>
                        <p className={expertsShow? "instrument__experts-dialog--hidden" : "instrument__experts-dialog--hidden"}>Intermarket Analysis</p>
                        <p className={expertsShow? "instrument__experts-dialog--link" : "instrument__experts-dialog--hidden"} onClick={expertsIxHandler}>Intermarket Analysis</p>
                    </div>
                </div>
                <div>
                    <h3>{instrument.stock}</h3>
                    <p>{instrument.code}</p>
                </div>
                <div>
                    <Button variant="instrument_basic" onClick={toggleShowingSplitsHandler}>Remove splits</Button>
                </div>
            </div>
        </div>
    } else {
        header = <div className="instrument--title">
            <div className="instrument--title__row">
                <NavLink to={visitedFrom} className="instrument--title__back"><AiOutlineArrowLeft size="36" color="black" /><span>Back to Results</span></NavLink>
                <div className="instrument__preview">

                    <h3>{instrument.stock}</h3>
                    <h3>{instrument.header}</h3>
                    <p>PREVIEW ONLY. Add to Favorite to work with this instrument</p>

                </div>
                <Button variant="instrument_basic" onClick={addInstrumentHandler}>Add to Favorite</Button>
            </div>
        </div>
    }

    // ----------- NEW PZ DISPLAY


    // 2. Get current year and get all days of this year
    const currentYear = new Date().getFullYear()
    const daysOfYear = []
    let i = 1
    for (let d = new Date(currentYear, 0, 1); d <= new Date(currentYear, 11, 31); d.setDate(d.getDate() + 1)) {
        daysOfYear.push({
            string: d.toDateString().slice(4)
        });
    }

    // var now = new Date();
    // var start = new Date(now.getFullYear(), 0, 0);
    // var diff = now - start;
    // var oneDay = 1000 * 60 * 60 * 24;
    // var day = Math.floor(diff / oneDay);

    const daysOfYearDivs = daysOfYear.map((el, index) => {
        let classString

        instrument.zones.forEach(zone => {
            // if (index === day) {
            //     classString = 'blue'
            // } else 
            if (index >= zone[1] && index <= zone[2]) {
                if (zone[0][0] > zone[0][1]) {
                    classString = 'green'
                } else if (zone[0][1] > zone[0][0]) {
                    classString = 'tomato'
                }
            }
        })

        return (
            <div 
                style={{backgroundColor: classString, width: '0.2739726027%'}}
                key={'day_of_year'+index+'_zone'}
            >
            </div>
        )
    })

    const daysOfYearTickers = daysOfYear.map((el, index) => {
        if (index === 0) {
            return (
                <div 
                    style={{backgroundColor: 'black', width: '0.2739726027%'}}
                    key={'day_of_year_zero_'+index}>

                </div>
            )
        } else if (daysOfYear[index-1].string.slice(0, 4) !== el.string.slice(0, 4)) {
            return (
                <div 
                    style={{backgroundColor: 'black', width: '0.2739726027%'}}
                    key={'day_of_year_main_'+index}
                >

                </div>
            )
        } else {
            return (
                <div style={{width: '0.2739726027%'}}
                    key={'day_of_year_other_'+index}>

                </div>
            )
        }
    })

    const daysOfYearMonths = months.map((el) => {
        return (
            <div style={{width: '8.33333333333%', display: 'flex'}}
                key={'month_'+el}
            >
                {el}
            </div>
        )
    })

    let lastPrice
    try {lastPrice = instrument.data[0].data[instrument.data[0].data.length-1][1]}
    catch {}

    // Volume Showing Option
    // let volumeCheckBoxDiv
    // if (instrument.data.findIndex(el => el.name === 'Volume') > -1) {
    //     volumeCheckBoxDiv = (
    //         <span style={{textAlign: 'center'}}>
    //             <input type='checkbox' checked={showVolume} onChange={e => setShowVolume(e.target.checked)} title='Display Volume on Chart' />
    //             <label>Display Volume on Chart</label>
    //         </span>
    //     )
    // }

    let volume = instrument.volume>0?
    <div>Volume: {instrument.volume}</div>
    :null;


    // Main Render
    return (
        <div className="instrument">
            {NavigateElement}
            {header}
            
            <div className="instrument--overview">
                <div className="overview">
                    <p>Daily Breakdown</p>
                    <p>Starting from today and ending on {today}</p>
                    <div className="overview--item">
                        <div className="overview--list">
                            {weekDays}
                        </div>
                        {/* <div className='overview__legend'>
                            <div><div className="instrument--overview__square segment--green"></div><p> - Positive</p></div>
                            <div><div className="instrument--overview__square segment--red"></div><p> - Negative</p></div>
                            <div><div className="instrument--overview__square segment--blue"></div><p> - Equal</p></div>
                        </div> */}
                    </div>
                </div>

                <div className="overview__ix-container">
                    <div>Latest Price: {lastPrice}</div>
                    {volume}
                    {ixDiv}
                </div>

                <div className="instrument--LBC instrument--LBC__content">
                    <p>LBC</p>
                    <span>
                        <input type="number" value={LBCValue} style={{width: "5rem"}} onChange={(e) => LBCBarsChangedHandler(e)} disabled={instrument.stockIsFavorite? false : true} min="0"/>
                        &nbsp;bars
                    </span>
                    <Button  variant="instrument_basic" className="instrument--models__LBC instrument--models__LBC--show" onClick={LBCRecalculateHandler} disabled={lbcDisabled}>Recalculate</Button>
                </div>
            </div>

            <div className="instrument__chartAndModels">
                <div className="instrument--chart">
                <HighchartsReact options={chartOptions} highcharts={Highcharts} constructorType={'stockChart'} oneToOne={true} />
                </div>
                {modelsDiv}
            </div>
            {/* {basicInfo} */}
            <div className="instrument--tzs">
                <h3>Predictable Zones For the Year</h3>
                <h4>Based on a 12 year analysis</h4>
                <div className='instrument__pz--container'>
                    <div className='instrument__pz' key='instrument_days_of_year'>
                        {daysOfYearDivs}
                    </div>
                    <div className='instrument__pz--legend' key='tickers'>
                        {daysOfYearTickers}
                    </div>
                    <div className='instrument__pz--legend' key='instrument_days_of_year_months'>
                        {daysOfYearMonths}
                    </div>
                </div>
                <span>Current Year</span>
                {zonesElement}
            </div>

            {instrument.stockIsFavorite? <Button variant="rounded" style="instrument_cancel" onClick={() => deleteInstrument(instrumentShown, instrument.type)}>Remove From Favorites</Button> : null}

            {/* DIALOGS */}
            <Dialog open={addDialog} type="add-model" closeClicked={() => setAddDialog(false)}>
                <h3>Add New Model</h3>
                <Button className="button__add" onClick={openAstroDialog}>Natural Cycles</Button>
                <Button className="button__add" onClick={openQSpecDialog}>Spectrum Analysis</Button>

                <Button className="button__model--cancel" onClick={() => setAddDialog(false)}>Cancel</Button>
            </Dialog>

            <Dialog open={astroOpen} type="astro" closeClicked={() => setAstroOpen(false)}>
                <AstroDialog 
                type="New"
                deleteVisible={false}
                loading={calculating}
                submitClicked={submitModelHandler}
                closeClicked={() => setAstroOpen(false)} />
            </Dialog>

            <Dialog open={qspecOpen} type="qSpec" closeClicked={() => setQspecOpen(false)}>
                <QSpecDialog 
                type="New"
                deleteVisible={false}
                stockName={instrumentShown}
                stockType={instrument.type}
                submitClicked={submitModelHandler}
                loading={calculating}
                closeClicked={() => setQspecOpen(false)} />
            </Dialog>

            <Dialog open={editDialog.editOpen} type={editDialog.type} closeClicked={closeEditDialog}>
                {dialogContent}
            </Dialog>

            <Dialog open={ixOpen} type="iX" outsideClick={() => toggleIx(false)}>
                <IxDialog 
                    instrument={instrument.stock}
                    ixData={instrument.ix}
                    closeClicked={() => toggleIx(false)}
                    instrData={instrument.data}
                    stockIsFavorite={instrument.stockIsFavorite}
                    ixSelected={() => loadFunc()}
                     />
            </Dialog>
        </div>
    )
}
