import React, {useContext} from 'react'
import {NavLink} from 'react-router-dom'

import UserContext from '../context/UserContext'
import useContexts from '../hooks/useContextCommunicator'

function SearchResults({results, submitClicked}) {
    // Hooks def
    const {addCustomInstrumentCommand, quickAddInstrumentCommand, browseStocksCommand} = useContexts()

    // Context def
    const {stocks, customStocks} = useContext(UserContext)

    // Handlers
    const linkClicked = (item) => {
        submitClicked(item)
        // setVisitedFrom('/search')
    }

    let searchResultsDiv
    if (results.results && results.results.length) {
        searchResultsDiv = results.results.map(item => {
            if (item === 'no results') {
                return <div key={'noResultsMessage'}>
                    <p style={{color:'red'}}>No results were found</p>
                </div>
            } else {
                const itemKey = "catStock_"+item.code
                    return (
                        <div key={itemKey} className="stockBrowser--search-results__result" onClick={() => linkClicked(item.code)}>
                            <div className="stockBrowser--search-results__result--add" onClick={() => quickAddInstrumentCommand(item.code)}></div>
                            <NavLink 
                                to='/instrument' className="stockBrowser--search-results__result--description">
                                    <div>{item.code} - {item.name} - Last Price: {item.price} {stocks.includes(item.code)? <span style={{color: 'green', fontWeight: 'bold'}}> FAVORITE</span> : ''}</div>
                            </NavLink>
                        </div>
                    )
            }
        })
    }

  return (
    <>
    {searchResultsDiv}
    </>
  )
}

export default SearchResults