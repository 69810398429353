import React from 'react'
import video1 from '../../videos/Astro.mp4'

export default function AstroHelp() {
  return (
    <>
        <h2>Natural Cycles Analysis</h2>
        <br />
        <h3>Overview</h3>
        <div>
          <p>A classic approach to cyclical analysis is by applying cycles in nature in attempt to predict the selected instrument's behavior. Astronomical cycles (those related to planets orbiting the Sun) are the most regular and stable cycles that we can apply to our instrument for analysis.</p>
          <p>Take a look at the Annual cycle: as seasons change, certain markets increase in activity and then decrease as new seasons come around. This is all part of the annual cycle as the Earth revovles around the Sun.</p>
          <p>TS Mobile allows us to apply different Natural Cycles to see if there is any correlation between an instrument behavior and a chosen cycle.</p>
        </div>
        <br />
        <h3>Working with Natural Cycles</h3>
        <div>
          <ul>
            <li>- When viewing your Instrument, click 'Add Model' and select 'Natural Cycles'</li>
            <li>- In the new window select your model parameters. Note: Dropdown named 'Model Type' includes the most common natural cycles for you to try.</li>
            <li>- Clicking submit will create the new model</li>
          </ul>
          <p>Note: We suggest starting this analysis with the Annual cycle first.</p>
          <video controls autoPlay muted loop playsInline style={{width: '80%', alignSelf: 'center'}}>
              <source src={video1} type="video/mp4" />
          </video>
        </div>
    </>
  )
}
