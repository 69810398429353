let status = "prod"

if (status === "dev") {
    module.exports = {
        nodeUrl: "http://localhost:5000"
    }
} else if (status === "prod") {
    module.exports = {
        nodeUrl: ''
    }
}