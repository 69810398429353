import React from 'react'

export default function Button(props) {
    let variant = ''
    let style = ''

    if (props.variant) {
        variant = 'button__'+props.variant
    }

    if (props.style) {
        style = 'button__'+props.style
    }
    
    return (
        <button className={`button ${variant} ${style} ${props.className}`} disabled={props.disabled} onClick={props.onClick}>
            {props.children}
        </button>
    )
}
