import React from 'react'
import {TbArrowBarDown} from 'react-icons/tb'

import video1 from '../../videos/Scanner_Start.mp4'
import video2 from '../../videos/Scanner_Results.mp4'
import video3 from '../../videos/Scanner_Changing.mp4'

export default function ScannerHelp() {
  return (
    <>
        <h2>Working with Scanner and Predictable Zones</h2>
        <br />
        <h3>Overview</h3>
        <div>
            <p>One of the main strengths of TS Mobile is the ability to perform complex analysis on the entire stock market, providing the results instantly.</p>
            <p>A good example is Predictable Zone analysis. TS Mobile goes through periods of predictable behavior for over 100000 symbols. The only thing left to you is to browse the results.</p>
            <p>But first, lets take a look at what is a Predictable Zone.</p>
        </div>
        <br />
        <h3>Getting Started with TS Mobile Scanner</h3>
        <div>
            <ul>
                <li>- When you login to TS Mobile, the Scanner page will be displayed by default. Alternatively you can select it from the menu on the top left corner</li>
                <li>- Enter the dates when you plan to enter and exit a trade (this option is selected by default and has an initial trade length of 7 days)</li>
                <li>- Select the Sector and Country of your preference. NOTE: This list also includes Non-Sector related categories such as Indexes, FOREX, Crypto, Futures & Commodities</li>
                <li>- If you wish to change the Search Type, you can do so in the field below "Sector/Type" dropdown</li>
                <li>- Clicking 'Find' will display the results based on your search criteria</li>
            </ul>
            <br />
            <video controls autoPlay muted loop playsInline style={{width: '80%', alignSelf: 'center'}}>
                <source src={video1} type="video/mp4" />
            </video>
        </div>
        <br />
        <h3>What is a Predictable Zone</h3>
        <div>
            <p>Most instruments demonstrate with time some sort of a repeated behavior.</p>
            <p>A most popular example is the 'Santa Claus Rally', where stock prices tend to rise around Christmas. The repeated behavior patterns may be related to a business (operational) cycle, seasonality, reporting guidelines, etc. While the exact reasons are not fully clear, the effect itself is well observed.</p>
            <p>It is important to identify those behaviors to better predict future movements of a particular insturment. There have been many ways to do so. Timing Solution software attempts to find these patterns of repeated behaviour by applying a variety of different techniques. TS Mobile focuses on one of these techniques, Predictable Zones.</p>
            <p>Just like the Santa Claus Rally, Predictable Zones are looking for some periods during the year when a particular instrument exhibits a certain repeated behavior.</p>
            <p>Major issue here is defining how much of price data is necessary to use for this analysis.</p>
            <br />
            <p>Let's take a look at Apple Inc for example.</p>
            <p>We can certainly take the entire price history to find Predictable Zones for this company's stock shares (the data from 1984 to present is available). However, this may not prove being reliable for many reasons. During its public history, Apple Inc has started out selling computers and then encountered massive success with creation of the iPhone. And, in the present day, Apple sells all sorts of technologies ranging from computers to phones, tablets, smart watches, etc.</p>
            <p>While the initial market may have had specific patterns related to public purchasing personal computers, Apple's demographic has completely changed since those early days. The patterns that were prevalent back then do not apply to the buying patterns of a modern Apple customer.</p>
            <p>With this in mind, it would be better and more accurate to limit how much price history data to use for Predictable Zone analysis. After years of experimenting, we have determined that the most reliable range to use is 12 years. Therefore, TS Mobile Predictable Zone analysis is done in regards to the last 12 years of price data for each symbol (if possible).</p>
            <p>This data is then collected and organized in a way to let you quickly search through it. You can specify what Country and Sector you want to work with, as well as the dates to enter and exit a particular trade. TS Mobile will then display the best instruments based on your criteria.</p>
        </div>
        <br />
        <h3>About different Search Types</h3>
        <div>
            <h4>By Entry and Exit Dates</h4>
            <p>This is the default selection for the search. The search engine looks for symbols that have a predictable zone that starts and ends within 2 days of the dates specified.</p>
            <h4>Entry within set period</h4>
            <p>Use this option if you are just interested at starting the trade within a specified date range (e.g you are planning to enter a trade this week and not sure how long you want to hold for).</p>
            <p>If you select this option, you can specify a Max Trade length. The default value is 15 days.</p>
            <p>Keep in mind that you will get results that may be shorter than the Trade Length you asked for</p>
            <h4>All zones within set period</h4>
            <p>This option is similar to the First option (By Entry and Exit Dates), but it will show you all Predictable Zones that start and end within the dates specified.</p>
            <p>For example: If you select Entry and Exit dates as Mar 12 and Mar 24, you can get one result where Predictable Zone starts on Mar 12 and ends on Mar 24; but you also can get a result where Predictable Zone starts on Mar 15 and ends on Mar 20. Both these results fiit the same criteria.</p>
        </div>
        <br />
        <h3>Working With Search Results</h3>
        <div>
            <h4>Overview</h4>
            <p>When you click 'Find', you get a list instruments that fit your search criteria. Each result is colored based on whether this is a Positive Predictable Zone or a Negative one.</p>
            <p>Positive zone means that in the last 12 years, there were 10 or more positive (upward) movements within set time period. Negative zone means that there were 10 or more negative (downward) movements within set time period.</p>
            <p>Each result includes the following information:</p>
            <ul>
                <li>Instrument Symbol and Name</li>
                <li>Predictable Zone start and end dates in MM/DD format.</li>
                <li>Last day closing price of the instrument</li>
                <li>How many positive & negative movements were observed in the last 12 years</li>
                <li>Average and Median price change for this Predictable Zone</li>
            </ul>
            <p>If you get a big list of results, you can sort them based on the price range or their median/average values.</p>
            <p>The options to sort results are located right above the actual list of results</p>
            <br />
            <p>Just like with results in the Search Instrument Page, you can click on an instrument to inspect it further or you can quickly add it to Your Favorites by clicking the '+' button</p>
            <br />
            <video controls autoPlay muted loop playsInline style={{width: '80%', alignSelf: 'center'}}>
                <source src={video2} type="video/mp4" />
            </video>
            <br />
            <h4>Changing Search Parameters</h4>
            <p>If you wish to change the parameters of your Scanner Search, you can do so by clicking the <span><TbArrowBarDown size="24" color="#333" /></span> button under 'Scan Parameters'</p>
            <p>You will then be presented with all the same search parameters you have entered before. You can modify them as you want and when 'Find' is clicked, new results will be displayed</p>
            <video controls autoPlay muted loop playsInline style={{width: '80%', alignSelf: 'center'}}>
                <source src={video3} type="video/mp4" />
            </video>
        </div>
    </>
  )
}
