import React from 'react'

import instrumentImg01 from '../../images/InstrumentHelp_01.png'
import instrumentImg02 from '../../images/InstrumentHelp_03.png'
import instrumentImg03 from '../../images/InstrumentHelp_02.png'

export default function InstrumentHelp() {
  return (
    <>
        <h2>Instrument Overview</h2>
        <br />
        <div>
            <p>Let us take a closer look at everything you see when you open an instrument. This is the example:</p>
            <br />
            <img style={{maxWidth: '100%'}} src={instrumentImg01} />
            <br />
            <p>If this instrument is part of Your Favorites, at the top you will see the TS Experts button, Instrument Symbol and Name, and the Remove Splits button.</p>
            <br />
            {/* <p><b>TS Experts</b> is the on-going project by Timing Solution to add more analysis tools for you to use. Clicking this button will show the list of all the tools currently available. Currently on Intermarket is available.</p>
            <p>Note: Predictable Zone Analysis, QSpectrum and Natural Cycles are not listed as they are part of the original TS Mobile package. Intermarket is a new addition, and other services will be added over time.</p>
            <br /> */}
            <p><b>Remove Splits</b> button looks for splits in the chart data and removes them. This is helpful when conducting your analysis of the instrument.</p>
            <br />
            <hr />
            <p>The row just above the price chart looks like this:</p>
            <img style={{maxWidth: '100%'}} src={instrumentImg02} />
            <p>The <b>Daily Breakdown</b> shows the instrument's general behaviour on a daily basis. The area on the left is broken down to 7 elements, representing 7 days starting with today. For example: if today is Wednesday the 19th, then the breakdown starts with today and goes until next Tuesday the 25th. Each one of these elements shows how the instrument has generally behaved on this day over the last 12 years.</p>
            <p>In this example, the first square represents today and it shows that the price of this instrument has moved down more than it has moved up on this day. However, the next day, the price has moved up more than it moved down, and so on...</p>
            <br />
            <p>In the middle we see the Latest Price of the instrument, and if there are any Intermarket Leading Indicators, the clickable element will be displayed.</p>
            <br />
            <p>On the right is the LBC setting. You can read more about LBC <a href='https://timingsolution.com/Doc/level_1/3.htm' target='_blank'>here</a>.</p>
            <p>This sets how much history to ignore when doing analysis using Natural Cycles or QSpectrum Analysis.</p>
            <p>The default value is 0. When you change it, pressing 'Recalculate' will update all of the models for this instrument with the new LBC setting.</p>
            <br />
            <p>Below is the Main Price Chart. This is where you can see the Price Chart along with any Models or Intermarket instruments that you have selected.</p>
            <br />
            <p>To the right of the chart is the list of models and Intermarket instruments that are found for this instrument. Clicking on any of them will open their corresponding window allowing you to edit/work with the model.</p>
            <br />
            <p>At the bottom is the <b>Predictable Zone</b> overview for this instrument. This shows all calculated predictable zones for this instrument over the course of a year.</p>
            <br />
            <p>Further zone breakdown is then listed below the graphical representation. The most relevant info on a zone is shown.</p>
            <p>Clicking on any of the zones will show a detailed yearly breakdown of how the instrument has behaved in the last 12 years within the date ranges of this zone. It will look like the picture below:</p>
            <img style={{maxWidth: '100%'}} src={instrumentImg03} />
            <p>Clicking on the same zone again will hide that information.</p>
            <br />
            <p>At the bottom of the page is the <b>Remove From Favorites</b>. This will remove the instrument from a list of Your Favorites.</p>
        </div>
    </>
  )
}
