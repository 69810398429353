import React, { useReducer, useState, useContext } from 'react'
import dataServer from '../api/dataServer'
import custServer from '../api/custServer'
import UserContext from './UserContext'
import {addModelColors, selectColor, addModelToStock, removeModel, editModel, removeStock, updateLBC, addIxData, updateInitStock} from '../utils/processor'
import moment from 'moment'

const DataContext = React.createContext();

// Reducer defs

const stockReducer = (state, action) => {
    switch (action.type) {
        case 'set_initial_stocks':
            const newState = addModelColors(action.payload)
            return newState
        case 'update_initial_stock':
            const tempState = updateInitStock(state, action.payload)
            return addModelColors(tempState)
        case 'final_color_assignment':
            const newColorState = addModelColors(state)
            return newColorState 
        case 'add_model_to_stock':
            return addModelToStock(state, action.payload.index, action.payload.model)
        case 'remove_model':
            return removeModel(state, action.payload.index, action.payload.modelIndex, action.payload.name)
        case 'edit_model':
            return editModel(state, action.payload.index, action.payload.modelIndex, action.payload.model)
        case 'remove_stock':
            return removeStock(state, action.payload)
        case 'add_stock':
            return [...state, action.payload]
        case 'update_models_with_new_lbc':
            return updateLBC(state, action.payload)
        case 'add_ix_data':
            return addIxData(state, action.payload)
        case 'purge':
            return []
        default:
            return state;
    }
}

const browsingReducer = (state, action) => {
    switch (action.type) {
        case 'set_stock':
            return action.payload
        case 'purge':
            return {loaded: false}
        default:
            return state;
    }
}

// const categoryReducer = (state, action) => {
//     switch (action.type) {
//         case 'set_categories':
//             return action.payload
//         default:
//             return state;
//     }
// }

const scannerReducer = (state, action) => {
    switch (action.type) {
        case 'update_results':
            return action.payload
        default:
            return state;
    }
}

export const DataProvider = ({ children }) => {
    // Auth context import
    const { stocks } = useContext(UserContext)

    // status state definition
    const [dataStatus, setDataStatus] = useState('download_initial_stocks')
    const [loadedCount, setLoadedCount] = useState(0)
    const [instrumentShown, setInstrumentShown] = useState(stocks.length ? stocks[0] : '')
    const [searchResults, setSearchResults] = useState({
            term: '',
            category: '',
            country: '',
            page: 1
    })
    const [visitedFrom, setVisitedFrom] = useState('')
    const [compareModels, setCompareModels] = useState([])
    const [addedFromSearch, setAddedFromSearch] = useState(false)
    const [scannerFromPrice, setScannerFromPrice] = useState(0)
    const [scannerToPrice, setScannerToPrice] = useState(0)
    const [scannerTypeFilter, setScannerTypeFilter] = useState('Med') //Options: Avg or Med for Average or Median sorting
    const [scannerTypeOrder, setScannerTypeOrder] = useState('-') //Options: + for ascending, - for descending

    // Reducers definition
    const [stockState, stockDispatch] = useReducer(stockReducer, [])
    const [browsingState, browsingDispatch] = useReducer(browsingReducer, {loaded: false})
    const [categories, setCategories] = useState(['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'Energy', 'Financial Services','Government Bonds', 'Healthcare', 'Industrials', 'Real Estate', 'Services', 'Technology', 'Utilities', 'Indexes', 'Futures', 'Forex', 'Crypto'])
    const [countries, setCountries] = useState(["USA","Argentina","Australia","Austria","Belgium","Brazil","Canada","Chile","China","Croatia","Denmark","Estonia","Finland","France","Germany","Greece","Hong Kong","Hungary","Iceland","India","Indonesia","Ireland","Israel","Italy","Korea","Latvia","Lithuania","Malaysia","Mexico","Netherlands","Norway","Pakistan","Peru","Philippines","Poland","Portugal","Romania","Russia","Singapore","South Africa","Spain","Sri Lanka","Sweden","Switzerland","Taiwan","Thailand","Turkey","UK","Vietnam"])

    const [countrySectors, setCountrySectors] = useState([
        {country: 'Argentina', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'Energy', 'Financial Services', 'Healthcare', 'Industrials', 'None', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country: 'Australia', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrials', 'None', 'Other', 'Real Estate', 'Technology', 'Utilities']},
        {country: 'Austria', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial Services', 'Healthcare', 'Industrials', 'None', 'Real Estate', 'Technology', 'Utilities']},
        {country:'Belgium', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrials', 'None', 'Other', 'Real Estate', 'Technology', 'Utilities']},
        {country: 'Brazil', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrials', 'None', 'Other', 'Real Estate', 'Technology', 'Utilities']},
        {country:'Canada', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrials', 'None', 'Other', 'Real Estate', 'Technology', 'Utilities']},
        {country:'Chile', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'Energy', 'Financial Services', 'Healthcare', 'Industrials', 'None', 'Other', 'Real Estate', 'Technology', 'Utilities']},
        {country:'Denmark', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'ETF', 'Energy', 'Financial Services', 'Healthcare', 'Industrials', 'None', 'Other', 'Real Estate', 'Technology', 'Utilities']},
        {country:'Estonia', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},   
        {country:'Finland', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'ETF', 'Energy', 'Financial Services', 'Healthcare', 'Industrials', 'Other', 'Real Estate', 'Technology', 'Utilities']},
        {country:'France', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrials', 'None', 'Other', 'Real Estate', 'Technology', 'Utilities']},
        {country:'Germany', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Greece', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Hong Kong', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Hungary', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'India', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Indonesia', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Ireland', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Iceland', sectors: ['Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'Financial Services', 'Industrials', 'Real Estate', 'Technology']},
        {country:'Israel', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Italy', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Korea', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Latvia', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Lithuania', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Malaysia', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Mexico', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Netherlands', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Norway', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Pakistan', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Peru', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Philippines', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Poland', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Portugal', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Romania', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Russia', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Singapore', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'South Africa', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country: 'Spain', sectors: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive', 'ETF', 'Energy', 'Financial Services', 'Healthcare', 'Industrials', 'None', 'Other', 'Real Estate', 'Technology', 'Utilities']},
        {country:'Sri Lanka', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Sweden', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Switzerland', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Thailand', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Turkey', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'UK', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'USA', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']},
        {country:'Vietnam', sectors: ['Basic Materials', 'Communication Services', 'Conglomerates', 'Consumer Cyclical', 'Consumer Defensive', 'Consumer Goods', 'ETF', 'Energy', 'Financial', 'Financial Services', 'Healthcare', 'Industrial Goods', 'Industrials', 'None', 'Other', 'Real Estate', 'Services', 'Technology', 'Utilities']}
    ])

    const [scannerState, scannerDispatch] = useReducer(scannerReducer, {
        params: {
            dateRange: [],
            category: "",
            country: ""
        },
        results: []
    })


    // Helper functions
    // Helper Functions
    const alertIfNoData = (model) => {
        if (model.data.length === 0 || !model.data) {
            alert('Not enough price history for this model. Change model parameters to get results.')
        }
    }

    //  ---------- functions
    const downloadBaseStock = async (stock) => {
            let stockChartData, stockChartHeader, stockDaily, stockIx, stockLBCBars, stockLBCTime,stockBasicInfo, zones
            try {
                const stockData = await dataServer.post('/getChartData', {stock})
                stockChartData = stockData.data
            } catch {
                stockChartData = []
            }

            try {
                const stockHeader = await dataServer.post('/getHeader', {stock})
                stockChartHeader = stockHeader.data
            } catch {
                stockChartHeader = `${stock} - Failed to Load`
            }

            try {
                const stockInfo = await dataServer.post('/getInfo', {stock})
                stockDaily = stockInfo.data.daily
                zones = [...stockInfo.data.zones]
                zones.forEach(item => {
                    let zoneBreakdown = item[0][3]
                    zoneBreakdown.sort(function(a,b) {return b.year - a.year})
                })
                stockIx = stockInfo.data.ix
                stockLBCBars = stockInfo.data.LBC.OSBars
                stockLBCTime = stockInfo.data.LBC.OSTime
                // stockBasicInfo = stockInfo.data.basicInfo

            } catch {
                stockDaily = []
                zones = []
                stockIx = []
                stockLBCBars = 0
                stockLBCTime = 0
                // stockBasicInfo = {
                //     sector: "None",
                //     industry: "None",
                //     mCap: "None",
                //     tPE: "None",
                //     fPE: "None",
                //     divYield: "None",
                //     wkHigh: "None",
                //     wkLow: "None"
                // }
            }

            // *************************************************
            // PROCESSING VOLUME AS A SEPARATE MODEL
            // This requires instrument screen to display volume on chart but
            // not show it as a model. This causes opening of model dialog to
            // be incorrect. I am going to simple show the last volume entry if any
            // and work on making a visual representation later. Potentially during
            // Web-app rewrite
            // *************************************************
            // let newData = [...stockChartData]
            // if (stockChartData.length > 1 && stockChartData[1].name !== 'Volume') {
            //     const newVolumeData = []
            //     newData[0].data.forEach(el => {
            //         if (el[2]) {
            //             newVolumeData.push([el[0], el[2]]);
            //         }
            //     })

            //     if (newVolumeData.length) {
            //         const newVolumeObj = {
            //             name: 'Volume',
            //             yAxis: 2,
            //             opacity: "0.5",
            //             header: 'Volume',
            //             visible: true,
            //             lineWidth: "1",
            //             data: newVolumeData,
            //             visibleTemp: true,
            //             mIndex: -1,
            //             color: "92bfb1",
            //         }
            //         // newData.splice(1, 1, newVolumeObj);
            //         const modelData = newData.slice(1)
            //         newData = [newData[0]]
            //         newData.push(newVolumeObj)
            //         newData = newData.concat(modelData)
            //     }
            // }
            
            let lastVolume = 0
            try {
                lastVolume = stockChartData[0].data[stockChartData[0].data.length-1][2]
            } catch {}
            const newStock = {
                stock, 
                data: stockChartData,
                loaded: true, 
                header: stockChartHeader,
                daily: stockDaily,
                zones: zones,
                ix: stockIx,
                LBCBars: stockLBCBars,
                LBCTime: stockLBCTime,
                basicInfo: stockBasicInfo,
                type: 'standard',
                volume: lastVolume
            }
            stockDispatch({ type: 'update_initial_stock', payload: newStock })
    }

    const downloadCustomStock = async (stock) => {
            try {
                const stockData = await custServer.post('/rdUsrInstr', {instrument:stock})
                const newStock = {
                    stock, 
                    data: stockData.data.data, 
                    loaded: true, 
                    header: stock,
                    daily: stockData.data.daily,
                    zones: stockData.data.zones,
                    ix: stockData.data.ix,
                    LBCBars: stockData.data.LBC.OSBars,
                    LBCTime: stockData.data.LBC.OSTime,
                    basicInfo: stockData.data.basicInfo,
                    type: 'custom'
                }
                stockDispatch({ type: 'update_initial_stock', payload: newStock })
            } catch (err) {
                const newStock = {
                    stock, 
                    data: [], 
                    loaded: true, 
                    header: `${stock} - Failed to Load`,
                    daily: [],
                    zones: [],
                    ix: [],
                    LBCBars: 0,
                    LBCTime: 0,
                    basicInfo: {
                        sector: "None",
                        industry: "None",
                        mCap: "None",
                        tPE: "None",
                        fPE: "None",
                        divYield: "None",
                        wkHigh: "None",
                        wkLow: "None"
                    },
                    type: 'custom'
                }
                stockDispatch({ type: 'update_initial_stock', payload: newStock })
                
            }
    }

    const sortByStrengthUps = (a, b) => {
        if (a.ups < b.ups) {
            return 1
        } else if (a.ups > b.ups) {
            return -1
        } else {
            return 0;
        }
    }

    const sortByStrengthDowns = (a, b) => {
        if (a.downs < b.downs) {
            return 1
        } else if (a.downs > b.downs) {
            return -1
        } else {
            return 0;
        }
    }

    const sortByTerm = (a, b) => {
        if (a.code < b.code){
            return -1
        } else if (a.code > b.code) {
            return 1
        } else {
            return 0
        }

    }

    const updateStockChartData = async (stock) => {
        stockDispatch({type: 'update_initial_stock', payload: stock})
    }

    // ----------------------Instrument Functions

    const updateCountry = (state) => {
        // console.log(state)
        scannerDispatch({type:'update_results', payload: state})
    }

    const startInitialDownload = async (newStocks, newCustStocks, country) => {
        const stockList = newStocks.concat(newCustStocks)
        if (instrumentShown === '') {
            setInstrumentShown(stockList[0])
        }

        const newScannerState = {
            params: {
                dateRange: [],
                category: "",
                country: country
            },
            results: []
        }
        scannerDispatch({type:'update_results', payload: newScannerState})

        // setDataStatus('download_initial_stocks')
        // 1. Create empty list of stocks, copy from user state
        const newStockState = await stockList.map(item => {
            return {
                stock: item,
                type: 'standard',
                loaded: false,
                data: null,
                header: null,
                daily: null,
                zones: null,
                ix: null,
                LBCBars: null,
                LBCTime: null,
                basicInfo: null
            }
        })
        stockDispatch({ type: 'set_initial_stocks', payload: newStockState})


        let count = 0
        // 1. Launch async downloading of base stocks
        newStocks.forEach(async stock => {
            await downloadBaseStock(stock)
            count++
            setLoadedCount(loadedCount+1)
        })

        // 2. Launch async downloading of custom stocks
        newCustStocks.forEach(async stock => {
            await downloadCustomStock(stock)
            count++
            setLoadedCount(loadedCount+1)
        })
        

        return true
    }

    const provideInstrument = () => {
        // error checking
        // check if logged out
        if (stockState === []) {
            return {
                header: 'None',
                data: [],
                zones: [],
                loaded: false
            }
        }

        // check active instrument
        const stockIndex = stockState.findIndex(el => el.stock === instrumentShown)
        let activeInstrument = ''
        if (instrumentShown === '' || !instrumentShown) {
            activeInstrument = stocks[0]
            setInstrumentShown(stocks[0])
        } else if (instrumentShown && stockIndex >= 0) {
            activeInstrument = instrumentShown
        } else if (instrumentShown === '' && !stocks.length) {
            return {
                stock: 'None',
                data: [],
                loaded: true,
                header: 'None',
                daily: [],
                zones: []
            }
        }

        // final call
        let instrument = stockState.find(stock => stock.stock === instrumentShown)
        if (!instrument) {
            return (null)
        }
        return instrument
    }

    const addModel = async (model, stock = instrumentShown) => {
        const stockIndex = stockState.findIndex(element => element.stock === stock)
        let NodeRequest
        // test if stock is custom

        if (model.type === 'astro') {
            NodeRequest = {
                stock,
                chartkind: stockState[stockIndex].type,
                model: model.type,
                modelParams: {
                    astro_model_kind: model.astro_model_kind,
                    astro_model_sm: model.astro_model_sm,
                    astro_model_overtones: model.astro_model_overtones,
                    Planet1: model.Planet1,
                    Planet2: model.Planet2,
                    zod: model.zod
                }
            }
        } else if (model.type === 'qspec') {
            NodeRequest = {
                stock,
                chartkind: stockState[stockIndex].type,
                model_index: model.model_index,
                model: model.type,
                modelParams: {
                    Kind: model.Kind,
                    SM: model.SM,
                    FSM: model.FSM,
                    SZ: model.SZ,
                    Overtones: model.Overtones,
                    Target: model.Target,
                    customCycles: model.customCycles,
                    chosen_cycles: model.chosen_cycles,
                }
            }
        }

        try {
            let newModel = await dataServer.post('/addModel', NodeRequest)
            newModel.data.color = selectColor(stockState[stockIndex].data.length-1)
            if (newModel.data.visibleTemp) {
                newModel.data.lineWidth = 1
            } else {
                newModel.data.lineWidth = 0
            }
            newModel.data.visible = true
            newModel.data.mIndex = stockState[stockIndex].data.length
            alertIfNoData(newModel.data)
            stockDispatch({ type: 'add_model_to_stock', payload: {
                index: stockIndex,
                model: newModel.data
            }})
            return newModel.data
        } catch (err) {
            alert(err.response.data)
            throw new Error(err.response.status)
        }
    }

    const deleteModel = async (modelIndex, name) => {
        const stockIndex = stockState.findIndex(element => element.stock === instrumentShown)
        try {
            const NodeRequest = {
                stock: instrumentShown,
                chartkind: stockState[stockIndex].type,
                modelIndex
            }
            await dataServer.post('/deleteModel', NodeRequest)
            const realModelIndex = modelIndex+1
            return stockDispatch({ type: 'remove_model', payload: {
                index: stockIndex,
                modelIndex: realModelIndex,
                name
            }})
            // return true
        } catch (err) {
            throw new Error(err.response.status)
        }
    }

    const editModel = async (modelIndex, modelParams) => {
        const stockIndex = stockState.findIndex(element => element.stock === instrumentShown)
        const NodeRequest = {
            stock: instrumentShown,
            chartkind: stockState[stockIndex].type,
            modelIndex,
            modelParams
        }
        const color = stockState[stockIndex].data[modelIndex+1].color
        try {
            const response = await dataServer.post('/editModel', NodeRequest)
            const newModel = {...response.data, color}
            alertIfNoData(response.data)
            stockDispatch({ type: 'edit_model', payload: {index:stockIndex, modelIndex, model: newModel} })
            return newModel
        } catch (err) {
            alertIfNoData({data: []})
            throw new Error(err.response.status)
        }
    }

    const removeData = () => {
        setDataStatus('empty')
        setInstrumentShown('')
        stockDispatch({ type: 'purge'})
    }

    const addInstrument = async () => {
        let object = {...browsingState}
        object.type = 'standard'
        object.LBCTime = moment(Date.now()).unix() * 1000
        object.LBCBars = 0
        stockDispatch({ type: 'add_stock', payload: object })
        browsingDispatch({ type: 'purge' })
        return object
    }

    const addCustomInstrument = async (stock) => {
        stock.type = 'custom'
        stock.LBCTime = moment(Date.now()).unix() * 1000
        stock.LBCBars = 0
        stockDispatch({ type: 'add_stock', payload: stock })
        browsingDispatch({ type: 'purge' })
        return stock
    }

    const quickAddInstrumentDataContext = async (stock) => {
        try {
            await dataServer.post('/addFavorite', {stock})
            const stockData = await dataServer.post('/getChartData', {stock})
            const stockHeader = await dataServer.post('/getHeader', {stock})
            const stockInfo = await dataServer.post('/getInfo', {stock})
            let zones = [...stockInfo.data.zones]
            zones.forEach(item => {
                let zoneBreakdown = item[0][3]
                zoneBreakdown.sort(function(a,b) {return b.year - a.year})
            })
            const newStock = {
                stock, 
                data: stockData.data, 
                loaded: true, 
                header: stockHeader.data,
                daily: stockInfo.data.daily,
                zones: zones,
                ix: stockInfo.data.ix,
                LBCBars: stockInfo.data.LBC.OSBars,
                LBCTime: stockInfo.data.LBC.OSTime
            }
            stockDispatch({ type: 'add_stock', payload: newStock})
            return true
        } catch (e) {
            return false
        }
    }

    const removeInstrument = async (stock) => {
        await stockDispatch({ type: 'remove_stock', payload: stock })
        if (stocks.length > 0) {
            setInstrumentShown(stocks[0])
        } else {
            setInstrumentShown('')
        }
    }

    const setLBCCommand = async (symbol, lbc, chartkind) => {
        const NodeRequest = {
            symbol,
            lbc,
            chartkind
        }

        try {
            let newOSTime
            const projLineData = await dataServer.post('/setLBC', NodeRequest)
            if (projLineData.data.all_proj_lines) {
                newOSTime = await dataServer.post('/getLBC', {symbol})
            } else {
                throw new Error('Set LBC command failed')
            }
    
            const stockIndex = stockState.findIndex(element => element.stock === instrumentShown)
            stockDispatch({ type: 'update_models_with_new_lbc', payload: {
                index: stockIndex,
                models: projLineData.data.all_proj_lines,
                LBCBars: newOSTime.data.bars,
                LBCTime: newOSTime.data.time
            }})
            return {
                models: projLineData.data.all_proj_lines,
                LBCBars: newOSTime.data.bars,
                LBCTime: newOSTime.data.time
            }
        } catch (e) {
            alert('Updating LBC failed')
        }
    }

    const setAddedFromSearchFunc = (type) => {
        setAddedFromSearch(type)
    }

    // ----------------------- Scanner functions
    const scanForStocks = async (dateRange, category, country, type, tradeLength, page, sortType) => {
        // Check if search params are not changed. If they are not, just run sorting algorithm
        const newParams = {
            submitted: true,
            dateRange,
            category,
            country,
        }
        if (scannerState.params.category === category && scannerState.params.country === country && scannerState.params.dateRange[0] === dateRange[0] && scannerState.params.dateRange[1] === dateRange[1] && scannerState.params.type === type && scannerState.params.tradeLength === tradeLength ) {
            let newState = {...scannerState}
            if (sortType == 1) {
                newState.results.sort(sortByStrengthUps)
            } else if (sortType == 2) {
                newState.results.sort(sortByStrengthDowns)
            } else {
                newState.results.sort((a,b) => a.relevance - b.relevance)
            }

            scannerDispatch({ type: 'update_results', payload: newState})
            return newState
        }

        // Params are changed, continuing with regular operation
        try {
            const NodeRequest = {
                start: dateRange[0],
                end: dateRange[1],
                country,
                category,
                type,
                tradeLength
            }
            const response = await dataServer.post('/findStocks', NodeRequest)
            // Results retreived, sort
            // sort by relevance
            const searchResults = response.data
            if (sortType === 1) {
                searchResults.sort(sortByStrengthUps)
            } else if (sortType === 2) {
                searchResults.sort(sortByStrengthDowns)
            } else {
                searchResults.sort((a, b) => a.relevance - b.relevance)
            }

            const output = {
                params: {
                    submitted: true,
                    dateRange,
                    category,
                    country,
                    type,
                    tradeLength
                },
                results: searchResults
            }

            scannerDispatch({ type: 'update_results', payload: output})
            return output
        } catch (err) {
            throw new Error(err.response.status)
        }
    }

    // NEW SEARCH FUNCTION.
    // This state is only used to preserve search parameters when user navigates away from screen.
    // Rest of search functionality is in the Search Screen
    const updateSearchParams = (term, category, country, page, industry=null) => {
        setSearchResults({
            term,
            category,
            country,
            industry,
            page
        })
    }

    const updateFromPrice = (item) => {
        if (typeof item === "number") {
            setScannerFromPrice(item)
        } else if (typeof item === 'string') {
            try {
                setScannerFromPrice(parseInt(item))
            } catch {}
        }
    }

    const updateToPrice = (item) => {
        if ((typeof item === "number")) {
            setScannerToPrice(item)
        } else if (typeof item === 'string') {
            try {
                setScannerToPrice(parseInt(item))
            } catch {}
        }
    }

    const updateType = (item) => {
        setScannerTypeFilter(item)
    }

    const updateOrder = (item) => {
        setScannerTypeOrder(item)
    }

    // ----------------------- Browsing functions
    const getBrowsedStock = async () => {
        if (instrumentShown === '') {
            return {stock: ''}
        }
        let response, stockHeader
        try {
            const NodeRequest = {
                stock: instrumentShown
            }

            try {
                response = await dataServer.post('/nonFaveInfo', NodeRequest)
            } catch {
                response = {
                    data: {
                        data: [],
                        daily: [],
                        ix: [],
                        basicInfo: {
                            divYield: "None",
                            fpe: "None",
                            industry: "None",
                            mCap: "None",
                            sector: "None",
                            wkHigh: "None",
                            wkLow: "None"
                        },
                        zones: []
                    }
                }
            }

            try {
                stockHeader = await dataServer.post('/getHeader', {stock: instrumentShown})
            } catch {
                stockHeader = {
                    data: 'N/A'
                }
            }
            
            let zones = [...response.data.zones]
            zones.forEach(item => {
                let zoneBreakdown = item[0][3]
                zoneBreakdown.sort(function(a,b) {return b.year - a.year})
            })

            let lastVolume = 0
            try {
                lastVolume = response.data.data[0].data[response.data.data[0].data.length-1][2]
            } catch {}
            const stockObject = {
                stock: instrumentShown,
                data: response.data.data,
                loaded: true,
                header: stockHeader.data,
                daily: response.data.daily,
                zones: zones,
                ix: response.data.ix,
                basicInfo: response.data.basicInfo,
                volume: lastVolume
            }
            browsingDispatch({ type:'set_stock', payload: stockObject})
            return stockObject
        } catch (err) {
            throw new Error(err.response.status)
        }
    }

    // ------------------------ Year View functions
    const getYearViewData = async (country, category) => {
        try {
            const NodeRequest = {
                country,
                category
            }
            
            const results = await dataServer.post('/getYearView', NodeRequest)
            return results.data
        } catch (err) {
            throw new Error(err.respnse.status)
        }
    }

    // ------------------------ Intermarket
    const getIxData = async (request) => {
        try {
            const response = await dataServer.post('/getIxData', request)
            return response.data[0].data
        } catch (e) {
            return []
        }
    }

    const showIxOnMain = async (instrument, le) => {
        // 1. try adding entry to db
        const NodeRequest = {
            symbol: instrument,
            le
        }

        try {
            await dataServer.post('/showIxOnMain', NodeRequest)
            return true
        } catch (e) {
            return false
        }

        // 2. since ix chart is already loaded into ix dialog, if entry added successfully, return success. thne ix sends command back to context with ix chart that updates state
        // 3. update state
    }

    const addIxDataToModels = async (ixData) => {
        const stockIndex = stockState.findIndex(element => element.stock === instrumentShown)
        ixData.color = selectColor(stockState[stockIndex].data.length-1)
        await stockDispatch({ type: 'add_model_to_stock', payload: {
            index: stockIndex,
            model: ixData
        }})
    }

    const removeIxFromMain = async (instrument, le) => {
        const NodeRequest = {
            symbol: instrument,
            le
        }
        try {
            const response = await dataServer.post('/hideIxOnMain', NodeRequest)
            if (response) {
                const stockIndex = stockState.findIndex(element => element.stock === instrumentShown)
                const modelIndex = stockState[stockIndex].data.findIndex(el => el.name === "I-"+le)
                stockDispatch({ type: 'remove_model', payload: {
                    index: stockIndex,
                    modelIndex: modelIndex,
                    name: stockState[stockIndex].data[modelIndex].name
                }})
            }
        } catch (e) {
        }
    }

    const updateEmptyIxData = async (instrument, leHeader) => {
        const le = leHeader.split('I-')[1]
        const response = await dataServer.post('/getIxChart', {le})
        const stockIndex = stockState.findIndex(element => element.stock === instrumentShown)
        const modelIndex = stockState[stockIndex].data.findIndex(el => el.name === leHeader)
        if (modelIndex > -1) {
            stockDispatch({ type: 'add_ix_data', payload: {index: stockIndex, modelIndex, data: response.data}})
        }
    }

    // Compare
    const compareGetStockData = () => {
        // Wait until download of data is complete
        // while (dataStatus !== 'download_complete') {

        // }

        // Push out stocks

        let results = []
        stockState.forEach(el => {
            results.push({
                key: el.stock+'CompareCard',
                instrument: el.stock,
                chartData: el.data[0].data
            })
        })
        return results
    }

    const updateCompareModels = (models) => {
        // For each model, find model data and update state with following object for each:
        // {
        //     stock,
        //     modelData
        // }
        // const resultingModelArray = []
        const newCompareState = []

        models.forEach(item => {
            const stockData = stockState.find(el => {
                if (el.stock === item.stock) {
                    const output = {...el}
                    return output
                }
            })
            const stockModelData = stockData.data.find(el => {
                if (el.mIndex === item.mIndex) {
                    let returnObj = {...el}
                    return returnObj
                }
            })
            newCompareState.push({...stockModelData, stock: item.stock, color: '#FF0000', yAxis: 1})
        })
        return newCompareState
    }

    // ******* THIS FUNCTION IS OBSOLETE *********
    const addModelCompare = async (model, stock = instrumentShown) => {
        const stockIndex = stockState.findIndex(element => element.stock === stock)
        let NodeRequest
        // test if stock is custom

        if (model.type === 'astro') {
            NodeRequest = {
                stock,
                chartkind: stockState[stockIndex].type,
                model: model.type,
                model_index: stockState[stockIndex].data.length,
                modelParams: {
                    astro_model_kind: model.astro_model_kind,
                    astro_model_sm: model.astro_model_sm,
                    astro_model_overtones: model.astro_model_overtones,
                    Planet1: model.Planet1,
                    Planet2: model.Planet2,
                    zod: model.zod
                }
            }
        } else if (model.type === 'qspec') {
            NodeRequest = {
                stock,
                chartkind: stockState[stockIndex].type,
                model_index: model.model_index,
                model: model.type,
                modelParams: {
                    Kind: model.Kind,
                    SM: model.SM,
                    FSM: model.FSM,
                    SZ: model.SZ,
                    Overtones: model.Overtones,
                    Target: model.Target,
                    customCycles: model.customCycles,
                    chosen_cycles: model.chosen_cycles,
                }
            }
        }

        try {
            let newModel = await dataServer.post('/addModelCompare', NodeRequest)
            newModel.data.color = selectColor(stockState[stockIndex].data.length-1)
            if (newModel.data.visibleTemp) {
                newModel.data.lineWidth = 1
            } else {
                newModel.data.lineWidth = 0
            }
            newModel.data.visible = true
            newModel.data.mIndex = stockState[stockIndex].data.length
            // stockDispatch({ type: 'add_model_to_stock', payload: {
            //     index: stockIndex,
            //     model: newModel.data
            // }})
            return newModel.data
        } catch (err) {
            alert(`${err.response.data.error}. ${stock}`)
            throw new Error(err.response.status)
        }
    }

    return (
        <DataContext.Provider value={{ stockState, browsingState, scannerState, instrumentShown, dataStatus, setInstrumentShown, provideInstrument, startInitialDownload, addModel, deleteModel, editModel, removeData, addInstrument, removeInstrument, scanForStocks, getBrowsedStock, categories, countries, getYearViewData, searchResults, visitedFrom, setVisitedFrom, getIxData, setLBCCommand, quickAddInstrumentDataContext, showIxOnMain, addIxDataToModels, removeIxFromMain, updateEmptyIxData, addCustomInstrument, compareGetStockData, updateCompareModels, compareModels, addModelCompare, loadedCount, setSearchResults, updateCountry, updateStockChartData, updateSearchParams, addedFromSearch, setAddedFromSearchFunc,
        scannerFromPrice, scannerToPrice, updateFromPrice, updateToPrice, scannerTypeFilter, scannerTypeOrder, updateType, updateOrder
        }}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContext