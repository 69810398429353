import React, { useState } from 'react'
import Button from '../components/Button';
import useInstrument from '../hooks/useInstrument';
import NumericInput from 'react-numeric-input';

export default function AstroDialog({type, deleteVisible, submitClicked, deleteClicked, closeClicked, modelIndex, loading}) {
    // context defs
    const {getModelParams} = useInstrument()

    // state defs
    const [loadComplete, setLoadComplete] = useState(false)

    const [astroModelOptions, setAstroModelOptions] = useState({
        astro_model_kind: 'Annual',
        astro_model_overtones: '12',
        astro_model_sm: 'All price',
        astro_color: '#000',
        Planet1: 'None',
        Planet2: 'None',
        zod: 'None'
    })

    // init
    React.useEffect(() => {
        const getModelInfo = async (modelIndex) => {
            const modelData = await getModelParams(modelIndex)
            let newState = {...astroModelOptions}
            newState.astro_model_kind = modelData.astro_model_kind
            newState.astro_model_overtones = modelData.astro_model_overtones
            newState.astro_model_sm = modelData.astro_model_sm
            if (modelData.astro_model_kind === 'Custom') {
                newState.Planet1 = modelData.Planet1
                newState.Planet2 = modelData.Planet2
                newState.zod = modelData.Zod
            }
            setAstroModelOptions(newState)
            setLoadComplete(true)
        }
        
        if (type==='Edit' && !loadComplete) {
            getModelInfo(modelIndex)
        }
    }, [loadComplete, astroModelOptions, modelIndex, type, getModelParams])

    const astroModelChange = (event) => {
        let newState = {...astroModelOptions}
        newState.astro_model_kind = event.target.value
        newState.Planet1 = 'None'
        newState.Planet2 = 'None'
        newState.zod = 'None'
        setAstroModelOptions(newState)
    }
    
    const astroSMChange = (event) => {
        let newState = {...astroModelOptions}
        newState.astro_model_sm = event.target.value
        setAstroModelOptions(newState)
    }
    
    const astroOvertonesChange = (value) => {
        let newState = {...astroModelOptions}
        newState.astro_model_overtones = value
        setAstroModelOptions(newState)
    }

    const customPlanetOneChange = (event) => {
        let newState = {...astroModelOptions}
        if (newState.astro_model_kind !== 'Custom') {
            newState.astro_model_kind = 'Custom'
        }
        newState.Planet1 = event.target.value
        setAstroModelOptions(newState)
    }

    const customPlanetTwoChange = (event) => {
        let newState = {...astroModelOptions}
        if (newState.astro_model_kind !== 'Custom') {
            newState.astro_model_kind = 'Custom'
        }
        newState.Planet2 = event.target.value
        setAstroModelOptions(newState)
    }

    const customZodiacChange = (event) => {
        let newState = {...astroModelOptions}
        if (newState.astro_model_kind !== 'Custom') {
            newState.astro_model_kind = 'Custom'
        }
        newState.zod = event.target.value
        setAstroModelOptions(newState)
    }

    const submitClickedHandler = () => {
        // Validate custom cycle proper submission
        if (astroModelOptions.astro_model_kind === 'Custom') {
            if (astroModelOptions.Planet1 === 'None') {
                return alert('For Custom Cycle need Two Planet entries and zodiac')
            } else if (astroModelOptions.Planet2 === 'None') {
                return alert('For Custom Cycle need Two Planet entries and zodiac')
            } else if (astroModelOptions.zod === 'None') {
                return alert('For Custom Cycle need Two Planet entries and zodiac')
            } else {
                const submitObject = {...astroModelOptions, type: 'astro'}
                submitClicked(submitObject)
            }
        } else {
            const submitObject = {...astroModelOptions, type: 'astro'}
            submitClicked(submitObject)
        }
    }

    // pre-render
    let customCycleClass = "astro__content-disabled"
    let customCycleOption = true
    if (astroModelOptions.astro_model_kind === 'Custom') {
        customCycleClass = "astro__content"
        customCycleOption = false
    }

    // Delete Button Visibility
    let deleteButton = deleteVisible ? <Button className="button__model--cancel" onClick={deleteClicked}>Remove</Button> : null

    return (
        <React.Fragment>
            <div id="form-dialog-title">Natural Cycles Module</div>
            <div className="astro">
            <div className="astro__content">
            Model Type
            {/* <select>
                <option value='astro'>Astro</option>
            </select> */}
            {/* <br /> */}
            <select value={astroModelOptions.astro_model_kind} onChange={astroModelChange}>
                <option value='Seasonal'>Seasonal</option>
                <option value='Quarterly'>Quarterly</option>
                <option value='Monthly'>Monthly</option>
                <option value='40 months Kitchin'>40 months Kitchin</option>
                <option value='4y Presidential'>4y Presidential</option>
                <option value='Annual'>Annual</option>
                <option value='Moon synodic(~29.5d)'>Moon synodic(~29.5d)</option>
                <option value='Moon tropical(~27.3d)'>Moon tropical(~27.3d)</option>
                <option value='Moon draconic(~27.2d)'>Moon draconic(~27.2d)</option>
                <option value='Venus synodic(~584d)'>Venus synodic(~584d)</option>
                <option value='Mercury synodic(~116d)'>Mercury synodic(~116d)</option>
                <option value='Mars synodic(`780d)'>Mars synodic(~780d)</option>
                <option value='Venus helio(~225d)'>Venus helio(~225d)</option>
                <option value='Mercury helio(~88d)'>Mercury helio(~88d)</option>
                <option value='Mars helio(~687d)'>Mars helio(~687d)</option>
                <option value='Jupiter helio(~11.9y)'>Jupiter helio(~11.9y)</option>
                <option value='Venus-Mars helio(~334d)'>Venus-Mars helio(~334d)</option>
                <option value='Venus-Jupiter helio(~237d)'>Venus-Jupiter helio(~237d)</option>
                <option value='Half Mercury synod.(~58d)'>Half Mercury synod.(~58d)</option>
                <option value='Custom'>Custom</option>
            </select>
            <br/>
            Stock Memory
            <select value={astroModelOptions.astro_model_sm} onChange={astroSMChange}>
                <option value='All price'>All Price</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
                <option value='5'>5</option>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
                <option value='9'>9</option>
                <option value='10'>10</option>
                <option value='12'>12</option>
                <option value='18'>18</option>
                <option value='24'>24</option>
            </select>
            <br />
            Overtones:
            <NumericInput min={1} value={astroModelOptions.astro_model_overtones} onChange={astroOvertonesChange} mobile />
            <br />
            </div>
            <div className={customCycleClass}>
                <p>Custom Natural Cycle</p>
                <span>Planet #1: </span>
                <select value={astroModelOptions.Planet1} onChange={customPlanetOneChange} disabled={customCycleOption}>
                    <option value='None'>None</option>
                    <option value='Sun'>Sun</option>
                    <option value='Moon'>Moon</option>
                    <option value='Mercury'>Mercury</option>
                    <option value='Venus'>Venus</option>
                    <option value='Mars'>Mars</option>
                    <option value='Jupiter'>Jupiter</option>
                    <option value='Saturn'>Saturn</option>
                    <option value='Uranus'>Uranus</option>
                    <option value='Neptune'>Neptune</option>
                    <option value='Pluto'>Pluto</option>
                    <option value='North Node'>North Node</option>
                </select>
                <br/>
                <span>Planet #2: </span>
                <select value={astroModelOptions.Planet2} onChange={customPlanetTwoChange} disabled={customCycleOption}>
                    <option value='None'>None</option>
                    <option value='Sun'>Sun</option>
                    <option value='Moon'>Moon</option>
                    <option value='Mercury'>Mercury</option>
                    <option value='Venus'>Venus</option>
                    <option value='Mars'>Mars</option>
                    <option value='Jupiter'>Jupiter</option>
                    <option value='Saturn'>Saturn</option>
                    <option value='Uranus'>Uranus</option>
                    <option value='Neptune'>Neptune</option>
                    <option value='Pluto'>Pluto</option>
                    <option value='North Node'>North Node</option>
                </select>
                <br/>
                <span>Zodiac: </span>
                <select value={astroModelOptions.zod} onChange={customZodiacChange} disabled={customCycleOption}>
                    <option value='None'>None</option>
                    <option value="Geo">Geo</option>
                    <option value="Helio">Helio</option>
                </select>
            </div>
            <div className="astro__color">
            </div>
            </div>
            <div className="dialog__actions--right">
                {deleteButton}
                <Button className="button__model--cancel" onClick={closeClicked}>
                    Cancel
                </Button>
                <Button 
                    disabled={loading ? true : false} 
                    className="button__model--submit" 
                    onClick={submitClickedHandler}>
                    {loading ? 'Calculating' : 'Submit'}
                </Button>
            </div>
        </React.Fragment>
    )
}
