import { useContext } from 'react'
import UserContext from '../context/UserContext'
import DataContext from '../context/DataContext'
import StorageContext from '../context/StorageContext'
import dataServer from '../api/dataServer'
import custServer from '../api/custServer'

export default () => {
    const {startInitialDownload, removeData, removeInstrument, instrumentShown, provideInstrument, getBrowsedStock, addInstrument, quickAddInstrumentDataContext, addCustomInstrument, stockState, updateCompareModels, addModelCompare, searchResults,updateCountry} = useContext(DataContext)

    const {isAuth, attemptLogin, loginWithToken, logout, removeUserInstrument, stocks, customStocks, addUserInstrument, removeUser} = useContext(UserContext)

    const {downloadStockList} = useContext(StorageContext)

    const downloadStocksAndStockList = async (stocks, customStocks, country) => {
        // 1. Download price chart data for all the stocks
        try {
            await startInitialDownload(stocks, customStocks, country)
            // 2. Obtain list of stocks by country and load into StorageContext
            await downloadStockList()
        } finally {
            // console.log('Displaying instrument',instrumentShown)
            
        }
    }

    const loginCommand = async (email, password) => {
        try {
            const userInfo = await attemptLogin(email, password)
            if (userInfo.error) {
                throw new Error(userInfo.error)
            }
            await downloadStocksAndStockList(userInfo.stocks, userInfo.customStocks)
        } catch (err) {
            throw new Error(err)
        }
    }

    const tokenLogin = async () => {
        if (!isAuth && localStorage.getItem('token')) {
            const token = localStorage.getItem('token')
            let userInfo
            try {
                userInfo = await loginWithToken(token)
                // let newSearchResults = {...searchResults}
                // newSearchResults.params.country = userInfo.country
                // updateCountry(newSearchResults)
                await downloadStocksAndStockList(userInfo.stocks, userInfo.customStocks, userInfo.country)
            } catch (e) {
                localStorage.removeItem('token')
                removeUser()
                removeData()
            }
        } else {
            return
        }
    }

    const logoutCommand = async () => {
        logout()
        removeData()
    }

    const deleteInstrument = async (stock, type) => {
        try {
            const NodeRequest = {stock}
            if (type === 'custom') {
                await custServer.post('/delUsrInstr', {instrument:stock})
            } else {
                await dataServer.delete('/removeFavorite', {data: NodeRequest})
            }
            await removeUserInstrument(stock)
            await removeInstrument(stock)
        } catch (err) {
        }
    }

    const getInstrument = async () => {
        // 1. If no instrument shown, provide first instrument
        // 2. If instrument is part of favorites, provide instrument by index (same command)
        // 3. If instrument is part of custom, provide instrument by index
        // let instrumentToShow = instrumentShown
        const searchInstrument = instrumentShown? instrumentShown : stocks[0]
        const favIndex = stocks.findIndex(el => el === searchInstrument)
        const customIndex = customStocks.findIndex(el => el === searchInstrument)
        let outputInstrument
        if (favIndex > -1 || customIndex > -1) {
            outputInstrument = provideInstrument()
        } else {
            outputInstrument = await getBrowsedStock()
        }
        if (outputInstrument) {
            return outputInstrument
        }
    }

    const addInstrumentCommand = async (stock) => {
        try {
            const NodeRequest = {
                stock
            }
            await dataServer.post('/addFavorite', NodeRequest)
            addUserInstrument(stock, 'standard')
            const object = addInstrument()
            return object
        } catch (err) {
            throw new Error (err.response.status)
        }
    }

    const addCustomInstrumentCommand = async (instrument) => {
        try {
            const NodeRequest = {
                instrument
            }
            const response = await custServer.post('/createUsrInstr', NodeRequest)
            addUserInstrument(instrument, 'custom')
            const stockObj = {
                stock: instrument,
                data: response.data.data,
                loaded: true,
                header: instrument,
                daily: response.data.daily,
                zones: [],
                ix: [],
                basicInfo: response.data.basicInfo,
                type: 'custom'
            }
            addCustomInstrument(stockObj)
            return true
            // ADD to both contexts
        } catch (err) {
            throw new Error (err.response.data)
        } 
    }

    const quickAddInstrumentCommand = async (stock) => {
        const addSuccess = await quickAddInstrumentDataContext(stock)
        if (addSuccess) {
            await addUserInstrument(stock, 'standard')
            addInstrument()
        } else {
            alert('Unable to add instrument using Quick Add Command')
        }
    }

    const compareModelSubmitClicked = async (settings) => {

        let missingStocksArray = []

        // 1. Get Data from Context on what models each stock has
        // Result: [ {stock, modelIndex, modelParams *initially zero*} ...]
        const modelList = []
        stockState.forEach(el => {
            const stockSymbol = el.stock
            el.data.forEach((rawModel, index) => {
                if (index !== 0) {
                    modelList.push({stock: stockSymbol, modelIndex: index, modelParams: {}})
                }
            })
        });

        // 2. Send request to server about model params for each stock and update array
        const results = await Promise.all(modelList.map(async model => {
            const NodeRequest = {
                symbol: model.stock,
                model_index: model.modelIndex-1
            }
            try {
                const response = await dataServer.post('/getModelData', NodeRequest)
                const responseData = {...response.data, stock: model.stock, mIndex: model.modelIndex}
                return responseData
            } catch (err) {
                return ({error: err.message})
            }
        }))
        
        // 3. Compare stock params to find matching models
        const contextResults = []
        results.forEach((el) => {
            if (el.model === settings.type) {
                if (el.astro_model_kind === settings.astro_model_kind) {
                    if (el.astro_model_sm === settings.astro_model_sm) {
                        if (el.astro_model_overtones === settings.astro_model_overtones) {
                            if (el.planet_one === settings.Planet1) {
                                if (el.planet_two === settings.Planet2) {
                                    contextResults.push({stock: el.stock, mIndex: el.mIndex})
                                }
                            }
                        }
                    }
                }
            }
        })

        // 6. Send that data to DataContext hook
        const endResult = updateCompareModels(contextResults)

        // 4. Find stocks that do not have the model
        let stocksWithMissingModel = [...stocks, ...customStocks]
        contextResults.forEach(el => {
            stocksWithMissingModel = stocksWithMissingModel.filter(item => item !== el.stock)
        })
        
        // 5. Send requests to create missing models
        if (stocksWithMissingModel.length) {
            missingStocksArray = await Promise.all(stocksWithMissingModel.map(async stock => {
                const nodeResponse = await addModelCompare(settings, stock)
                return {...nodeResponse, stock, color: '#FF0000', yAxis: 1}
            }))
        }
        missingStocksArray.forEach(item => {
            endResult.push(item)
        })

        return endResult
    }

    const browseStocksCommand = async (country, category, page) => {
        const NodeRequest = {
            country,
            category,
            page
        }

        const results = await dataServer.post('/getCategories', NodeRequest)
        return results.data
    }

    return {loginCommand, tokenLogin, logoutCommand, deleteInstrument, getInstrument, addInstrumentCommand, quickAddInstrumentCommand, addCustomInstrumentCommand, compareModelSubmitClicked, browseStocksCommand}
}