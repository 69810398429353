import React, { useContext, useState, useEffect } from 'react'
// import {NavLink} from 'react-router-dom'

import { BsFillCaretRightFill, BsFillCaretLeftFill } from "react-icons/bs";

import DataContext from '../context/DataContext'
import StorageContext from '../context/StorageContext'
import SearchResults from '../components/SearchResults'

export default function Search() {
    // Constants DEF
    const RESULTS_PER_PAGE = 40

    // context def
    const {categories, countries, setInstrumentShown, updateSearchParams, searchResults: searchResultParams, setVisitedFrom} = useContext(DataContext)
    const {state} = useContext(StorageContext)

    // state def
    const [searchResultsState, setSearchResultsState] = useState({
        currentPage: 0,
        searchResults: 0,
        totalPages: 0,
        results: []
    })
    const [selectedCategory, setSelectedCategory] = useState('All')
    const [selectedCountry, setSelectedCountry] = useState('All')
    const [term, setTerm] = useState('')
    const [allResults, setAllResults] = useState([])
    const [fromPrice, setFromPrice] = useState(0)
    const [toPrice, setToPrice] = useState(0)
    const [sectorIndustryPairs, setSectorIndustryPairs] = useState([])
    const [selectedIndustry, setSelectedIndustry] = useState('')

    // Helper functions
    const newResultElement = (el) => {
        let industry = null
        if (el.industry) {
            industry = el.industry.replaceAll('"','')
        }

        return {
            code: el.symbol,
            name: el.name,
            price: el.price,
            industry: industry
        }
    }

    const collectAllStocksIntoArray = () => {
        const newState = []
        state.forEach(country => {
            country.stocks.forEach((el) => {
                newState.push(newResultElement(el))
            })
        })
        setAllResults([])
        return newState
    }

    const collectStocksOfOneCategory = (category, countryList=[]) => {
        const newState = []


        if (!countryList.length) {
            state.forEach(country => {
                country.stocks.forEach((el) => {
                    if (el.category === category) {
                        newState.push(newResultElement(el))
                    }
                })
            })
        } else {
            countryList[0].stocks.forEach((el) => {
                if (el.category === category) {
                    newState.push(newResultElement(el))
                }
            })
        }

        setAllResults([])
        return newState
    }

    const setNewSearchResultState = (currentPage=1, searchResults, totalPages=1, originalList) => {
        const results = originalList.filter((el, index) => {
            if ((currentPage-1)*RESULTS_PER_PAGE <= index && index < (currentPage*RESULTS_PER_PAGE)) {
                return true
            }
        })

        const resultsSorted = results.sort(function(a, b) {
            return a.code.length - b.code.length
        })
        setSearchResultsState({
            currentPage,
            searchResults,
            totalPages,
            results: resultsSorted
        })
    }

    const displayStocksFromList = (category=selectedCategory, country=selectedCountry, updateState=true) => {
        // Account for categories that are stored as countries in context
        const categoriesAsCountries = ['Crypto', 'Forex', 'Futures', 'Index', 'Government Bonds']
        let controlCountry
        if (categoriesAsCountries.includes(category)) {
            controlCountry = category
        } else if (category === 'Indexes') {
            controlCountry = 'Index'
        } else {
            controlCountry = country
        }

        const countryObject = state.filter(el => el.country === controlCountry)

        if (!countryObject[0] || !countryObject[0].stocks) {
            if (country !== 'All') {
                return console.log("No Stocks for", country)
            }
        }

        // Main search function
        if (country === 'All' && category === 'All') {
            const results = collectAllStocksIntoArray()
            if (updateState) {
                setNewSearchResultState(1, results.length, 1, results)
            } else {
                return results
            }
        } else if (controlCountry === country && category === 'All') {
            const newResults = []
            countryObject[0].stocks.forEach(el => {
                newResults.push(newResultElement(el))
            })
            const currentPage = 1
            const searchResults = newResults.length
            let totalPages = parseInt(searchResults/RESULTS_PER_PAGE)+1
            if (updateState) {
                setAllResults(newResults)
                setNewSearchResultState(currentPage, searchResults, totalPages, newResults)
            } else {
                return newResults
            }
        } else if (controlCountry === 'All' && category !== 'All') {
            const results = collectStocksOfOneCategory(category)
            const searchResults = results.length
            const totalPages = parseInt(searchResults/RESULTS_PER_PAGE)+1

            if (updateState) {
                setAllResults(results)
                setNewSearchResultState(1, searchResults, totalPages, results)
            } else {
                return results
            }
        } else if (controlCountry === country && category !== 'All') {
            const tempResults = countryObject[0].stocks.filter(el => el.category === category)
            const newResults = []
            tempResults.forEach(el => {
                newResults.push(newResultElement(el))
            })
            const currentPage = 1
            const searchResults = newResults.length
            let totalPages = parseInt(searchResults/RESULTS_PER_PAGE)+1
            if (updateState) {
                setAllResults(newResults)
                setNewSearchResultState(currentPage, searchResults, totalPages, newResults)
            } else {
                return newResults
            }
        } else if (controlCountry === category || controlCountry === 'Index') {
            const newResults = []
            countryObject[0].stocks.forEach(el => {
                newResults.push(newResultElement(el))
            })
            const currentPage = 1
            const searchResults = newResults.length
            let totalPages = parseInt(searchResults/RESULTS_PER_PAGE)+1
            if (totalPages === 0) {
                totalPages = 1
            }
            if (updateState) {
                setAllResults(newResults)
                setNewSearchResultState(currentPage, searchResults, totalPages, newResults)
            } else {
                return newResults
            }
        }
    }

    const filterStocksByIndustry = (industry=selectedIndustry, country=selectedCountry, stockList=[]) => {
        if (!stockList.length) {
            const countryObject = state.filter(el => el.country === country)
            const results = collectStocksOfOneCategory(selectedCategory, countryObject)
            const filteredResults = results.filter(el => el.industry === industry)
            
            const currentPage = 1
            const searchResults = filteredResults.length
            setAllResults(filteredResults)
            let totalPages = parseInt(searchResults/RESULTS_PER_PAGE)+1
            if (totalPages === 0) {
                totalPages = 1
            }
    
            setAllResults(filteredResults)
            setNewSearchResultState(currentPage, searchResults, totalPages, filteredResults)
        } else {
            // Stock list has been passed, take it and only return the filtered stocklist
            return stockList.filter(el => el.industry === industry)
        }
    }

    // -------------------- initial load
    useEffect(() => {
        // 1. SEARCH CODE
        // Check if there were any search params before. If yes, load those instead, otherwise load all stocks
        if (!searchResultParams.term && !searchResultParams.category && !searchResultParams.country && searchResultParams.page === 1) {
            // NO SEARCH PARAMS, LOAD ALL STOCKS
            // 1. load all symbols and names from storage
            const newState = collectAllStocksIntoArray()

            //2. Take all results and render only a portion of them
            const searchResults = newState.length
            const totalPages = parseInt(newState.length/RESULTS_PER_PAGE)+1
            const currentPage = 1
            setNewSearchResultState(currentPage, searchResults, totalPages, newState)
        } else {
            // SEARCH PARAMS DETECTED. FIRST FILTER BY CATEGORY & COUNTRY, THEN APPLY SEARCH
            let newResults = displayStocksFromList(searchResultParams.category, searchResultParams.country, false)
            if (searchResultParams.industry) {
                newResults = filterStocksByIndustry(searchResultParams.industry, searchResultParams.country, newResults)
            }
            if (searchResultParams.term) {
                const searchedResults = newResults.filter(el => el.code.toLowerCase().includes(searchResultParams.term.toLowerCase()) || el.name.toLowerCase().includes(searchResultParams.term.toLowerCase()))
                
                const totalPages =parseInt(searchedResults.length/RESULTS_PER_PAGE)+1
                setAllResults(searchedResults)
                setNewSearchResultState(searchResultParams.page, searchedResults.length, totalPages, searchedResults)

                if (!term) {
                    setTerm(searchResultParams.term)
                }
            } else {
                const totalPages = parseInt(newResults.length/RESULTS_PER_PAGE)+1
                setAllResults(newResults)
                setNewSearchResultState(searchResultParams.page, newResults.length, totalPages, newResults)
            }
            setSelectedCategory(searchResultParams.category)
            setSelectedCountry(searchResultParams.country)
            if (searchResultParams.industry) {
                setSelectedIndustry(searchResultParams.industry)
            }
        }

        // ------------------------------
        // 2. Set Category/Industry Pairs
        if (!sectorIndustryPairs.length && state && state.length) {
            const allIndustriesRaw = new Set()
            state.forEach(el => {
                el.stocks.forEach(stock => {
                    try {
                        allIndustriesRaw.add(JSON.stringify({sector: stock.category, industry: stock.industry.replaceAll('"','')}))
                    } catch {}
                })
            })
            const allIndustries = [...allIndustriesRaw].map((item) => {
                if (typeof item === 'string') return JSON.parse(item)
                else if (typeof item === 'object') return item
            })
            setSectorIndustryPairs(allIndustries)
        }
    }, [state])


    // -------------------- handlers
    const movePageBack = () => {
        // If no search results, scan all the stocks to display the next page
        // Else display next set from search results
        if (!allResults.length) {
            const newState = collectAllStocksIntoArray()

            const searchResults = newState.length
            let totalPages = searchResultsState.totalPages
            const currentPage = searchResultsState.currentPage - 1
            setNewSearchResultState(currentPage, searchResults, totalPages, newState)
        } else {
            const searchResults = allResults.length
            let totalPages = searchResultsState.totalPages
            const currentPage = searchResultsState.currentPage - 1
            setNewSearchResultState(currentPage, searchResults, totalPages, allResults)
        }
    }

    const movePageForward = () => {
        
        // If no search results, scan all the stocks to display the next page
        // Else display next set from search results
        if (!allResults.length) {
            const newState = collectAllStocksIntoArray()

            const searchResults = newState.length
            let totalPages = searchResultsState.totalPages
            const currentPage = searchResultsState.currentPage + 1
            setNewSearchResultState(currentPage, searchResults, totalPages, newState)
        } else {
            const searchResults = allResults.length
            let totalPages = searchResultsState.totalPages
            const currentPage = searchResultsState.currentPage + 1
            setNewSearchResultState(currentPage, searchResults, totalPages, allResults)
        }
    }

    const searchHandler = (e) => {
        e.preventDefault()
        if (term) {
            // If no search results, look through the entire stock database
            // Else filter through search results only
            if (selectedCategory === 'All' && selectedCountry === 'All') {
                const newState = collectAllStocksIntoArray()
                const results = newState.filter(el => el.code.toLowerCase().includes(term.toLowerCase()) || el.name.toLowerCase().includes(term.toLowerCase()))
                const currentPage = 1
                const searchResults = results.length
                let totalPages = parseInt(results.length/RESULTS_PER_PAGE)+1
                setAllResults(results)
                setNewSearchResultState(currentPage, searchResults, totalPages, results)
            } else {
                const newResults = displayStocksFromList(selectedCategory, selectedCountry, false)
                const results = newResults.filter(el => el.code.toLowerCase().includes(term.toLowerCase()) || el.name.toLowerCase().includes(term.toLowerCase()))
                const currentPage = 1
                const searchResults = results.length
                let totalPages = parseInt(results.length/RESULTS_PER_PAGE)+1
                setAllResults(results)
                setNewSearchResultState(currentPage, searchResults, totalPages, results)
            }
        } else {
            return alert('Please enter a symbol or name to start search')
        }
    }

    const categoryChangedHandler = (category) => {
        setSelectedCategory(category)
        setSelectedIndustry('')
        setTerm('')
        displayStocksFromList(category)
    }

    const countryChangedHandler = (country) => {
        setSelectedCountry(country)
        setSelectedCategory('All')
        setSelectedIndustry('')
        setTerm('')
        displayStocksFromList('All', country)
    }

    const preNavigationHandler = (item) => {
        // Save search params to state before navigating away
        updateSearchParams(term, selectedCategory, selectedCountry, searchResultsState.currentPage, selectedIndustry)

        // Navigate away
        setInstrumentShown(item)
        setVisitedFrom('/search')
    }

    const filterPriceHandler = () => {
        // Validation
        if (toPrice < fromPrice) {
            return alert("Price range incorrect. From Price must be lower than To Price")
        }

        const filterResults = (array, from, to) => {
            return array.filter(el => {
                const price = parseFloat(el.price)
                if ((price >= from) && (price <= to)) {
                    return true
                }
            })
        }

        let filteredResults

        if ((fromPrice == 0) && (toPrice == 0)) {
            console.log('Resetting search')
            if (allResults && allResults.length) {
                filteredResults = allResults
            } else if (selectedCategory === 'All') {
                filteredResults = collectAllStocksIntoArray()
            } else {
                filteredResults = collectStocksOfOneCategory(selectedCategory)
            }
        } else if (allResults && allResults.length) {
            filteredResults = filterResults(allResults, fromPrice, toPrice)
        } else if (selectedCategory === 'All') {
            const newState = collectAllStocksIntoArray()
            filteredResults = filterResults(newState, fromPrice, toPrice)
        } else {
            const newState = collectStocksOfOneCategory(selectedCategory)
            filteredResults = filterResults(newState, fromPrice, toPrice)
        }

        let totalPages = parseInt(filteredResults.length/RESULTS_PER_PAGE)+1
        setNewSearchResultState(1, filteredResults.length, totalPages, filteredResults)
    }

    const industryChangedHandler = (industry) => {
        setTerm('')
        setSelectedIndustry(industry)
        filterStocksByIndustry(industry)
    }

    // -------------------- pre-render
    // categories
    const modifiedCategories = [...categories]
    modifiedCategories.unshift('All')
    const categoriesDiv = modifiedCategories.map(el => {
        let classString = `stockBrowser--rightSide__list-item${el === selectedCategory?' stockBrowser--rightSide__list-item-selected': ''}`
        return (
            <div
                className={classString}
                key={'category-'+el}
                onClick={() => categoryChangedHandler(el)}
            >
                {el}
            </div>
        )
    })

    let industriesToDisplay 
    if (selectedCategory !== 'All') {
        industriesToDisplay = sectorIndustryPairs.filter(el => el.sector === selectedCategory)
    } else {
        industriesToDisplay = []
    }

    const industriesDiv = industriesToDisplay.map((el, index) => {
        let classString = `stockBrowser--rightSide__list-item${el.industry === selectedIndustry?' stockBrowser--rightSide__list-item-selected': ''}`
        return (
            <div
                className={classString}
                key={'industry-'+el.industry+index}
                onClick={() => industryChangedHandler(el.industry)}
            >
                {el.industry}
            </div>
        )
    })

    // countries
    const modifiedCountries = [...countries]
    modifiedCountries.unshift('All')
    const countriesDiv = modifiedCountries.map(el => {
        let classString = `stockBrowser--rightSide__list-item${el === selectedCountry?' stockBrowser--rightSide__list-item-selected': ''}`
        return (
            <div
                className={classString}
                key={'country-'+el}
                onClick={() => countryChangedHandler(el)}
            >
                {el}
            </div>
        )
    })

    let resultString
    if (searchResultsState.currentPage === searchResultsState.totalPages) {
        resultString = `${searchResultsState.searchResults}`
    } else {
        resultString = `${searchResultsState.currentPage*RESULTS_PER_PAGE}`
    }

    // ---- DROPDOWNS ------------
    const categoryList = modifiedCategories.map(item => {
        return (
            <option key={'category-'+item} value={item}>{item}</option>
        )
    })

    const countryList = modifiedCountries.map(item => {
        return (
            <option key={'country-'+item} value={item}>{item}</option>
        )
    })

    const industryList = industriesToDisplay.map(item => {
        return (
            <option key={'industry-'+item.industry} value={item.industry}>{item.industry}</option>
        )
    })

    // final render
    return (
        <div className='stockBrowser'>
            {/* Search Bar */}
            <div className='stockBrowser--searchBar'>
                <label>Search: </label>
                <form style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                    <input 
                        value={term}
                        onChange={(e) => setTerm(e.target.value)}
                        placeholder='Enter Symbol or Name here'
                    />
                    <button
                        onClick={(e) => searchHandler(e)}
                    >
                        Search
                    </button>
                </form>
            </div>
            <div className='stockBrowser--dropdowns'>
                
                <div className='stockBrowser--dropdowns__row'>
                    {/* Country */}
                    <div style={{display: 'flex'}}>
                        <span>Country: </span>
                        <select title='Country' value={selectedCountry} onChange={e => countryChangedHandler(e.target.value)}>
                            {countryList}
                        </select>
                    </div>

                    {/* Price Filter */}
                    <div>
                        <div className='stockBrowser--price'>
                            <label>Price Range From:</label>
                            <input 
                                type='number' 
                                value={fromPrice}
                                onChange={(e) => setFromPrice(e.target.value)}
                            />
                            <label>To:</label>
                            <input 
                                type='number' 
                                value={toPrice}
                                onChange={(e) => setToPrice(e.target.value)}
                            />
                            <button
                                onClick={filterPriceHandler}
                            >
                                Filter
                            </button>
                        </div>
                        <span>Setting both values to 0 will show all results</span>
                    </div>
                </div>
                <div  className='stockBrowser--dropdowns__row'>
                    {/* Category */}
                    <div>
                        <span>Sector:</span>
                        <select title='Category' value={selectedCategory} onChange={e => categoryChangedHandler(e.target.value)}>
                            {categoryList}
                        </select>
                    </div>
                    {/* Category */}
                    <div>
                        <span>Industry:</span>
                        <select title='Industry' value={selectedIndustry} onChange={e => industryChangedHandler(e.target.value)}>
                            {industryList}
                        </select>
                    </div>
                </div>
            </div>

            {/* Bottom Area */}
            <div className='stockBrowser--bottomDiv'>
                <div className='stockBrowser--searchResults'>
                    {/* CONTROL BAR HERE */}
                    <div className='stockBrowser--searchControls'>
                        <span>
                            Total Results: {searchResultsState.searchResults}
                        </span>
                        <span>
                            Showing results: {(searchResultsState.currentPage-1)*RESULTS_PER_PAGE+' - '+ resultString}
                        </span>
                        <span style={{display: 'flex'}}>
                            <div
                                style={{cursor: searchResultsState.currentPage <=1 ? 'default' : 'pointer'}} 
                                onClick={searchResultsState.currentPage <=1 ? null : movePageBack}
                                className='stockBrowser--searchButton'>
                                    <BsFillCaretLeftFill
                                    color={searchResultsState.currentPage <=1 ? '#ddd' : 'black'}
                                    size={'1.5rem'}
                                    />
                            </div>
                            <div
                                style={{cursor: searchResultsState.totalPages === searchResultsState.currentPage ? 'default' : 'pointer'}} 
                                onClick={searchResultsState.currentPage === searchResultsState.totalPages ? null : movePageForward}
                                className='stockBrowser--searchButton'>
                                    <BsFillCaretRightFill
                                    color={searchResultsState.currentPage === searchResultsState.totalPages ? '#ddd' : 'black'}
                                    size={'1.5rem'}
                                    />
                            </div>
                        </span>
                    </div>
                    {/* Search Results Here */}
                    <div className='stockBrowser--search-results'>
                        <SearchResults 
                            results={searchResultsState}
                            submitClicked={(e) => preNavigationHandler(e)}
                        />
                    </div>
                </div>
                <div className='stockBrowser--selections'>
                    {/* Price Filter */}
                    <div className='stockBrowser--rightSide'>
                        <label>Price Range:</label>
                        <p>Setting both values to 0 will show all results</p>
                        <div className='stockBrowser--price'>
                            <label>From:</label>
                            <input 
                                type='number' 
                                value={fromPrice}
                                onChange={(e) => setFromPrice(e.target.value)}
                            />
                            <label>To:</label>
                            <input 
                                type='number' 
                                value={toPrice}
                                onChange={(e) => setToPrice(e.target.value)}
                            />
                            <button
                                onClick={filterPriceHandler}
                            >
                                Filter
                            </button>
                        </div>
                    </div>
                    {/* Countries */}
                    <div className='stockBrowser--rightSide' style={{flex: 1, marginBottom: '1rem'}}>
                        <span>Country: </span>
                        <select title='Country' value={selectedCountry} onChange={e => countryChangedHandler(e.target.value)} style={{width: '100%'}}>
                            {countryList}
                        </select>
                        {/* <label>Country:</label>
                        <div className='stockBrowser--rightSide__list' style={{overflow: 'auto'}}>
                            {countriesDiv}
                        </div> */}
                    </div>
                    {/* Categories */}
                    <div className='stockBrowser--rightSide'>
                        <label>Sector:</label>
                        <div className='stockBrowser--rightSide__list' style={{overflow: 'auto'}}>
                            {categoriesDiv}
                        </div>
                    </div>
                    {/* Industries */}
                    <div className='stockBrowser--rightSide'>
                        <label>Industry:</label>
                        <div className='stockBrowser--rightSide__list' style={{overflow: 'auto'}}>
                            {industriesDiv}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}