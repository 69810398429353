import React, { useRef, useEffect } from 'react'

export default function Dialog({ outsideClick, children }) {
    const wrapperRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                outsideClick()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [wrapperRef, outsideClick])

    return (
        <div ref={wrapperRef} className={`drawer`}>
            {children}
        </div>
    )
}
